import { isProduction, isSecurity } from '@utils/environment';
import { apiRequest, generateDataOrParams } from './apiConfig';

let baseURL;

if (isProduction) {
  baseURL = 'https://payments.marketing360.com/v1';
} else if (isSecurity) {
  baseURL = 'https://payments.security.marketing360.com/v1';
} else {
  baseURL = 'https://payments.stage.marketing360.com/v1';
}

export const paymentsUrl = baseURL.replace('/v1', '');

export const apiPayments = ({
  url,
  method,
  payload,
  headers,
  cache = false,
  showEndpoint = false
}) => {
  const baseRequest = apiRequest(baseURL);
  const dataOrParams = generateDataOrParams(method);
  const config = {
    url,
    method,
    headers,
    [dataOrParams]: payload,
    cache
  };

  // Tacking these lines onto api helpers will expose the full url that any
  // individual request targets. In turn, it can be used as a stable key in our
  // swr cache that is tightly coupled to the request itself. This covers the
  // account change case any time that the number is part of the URL.
  if (showEndpoint) {
    return {
      endpoint: `${baseURL}/${baseRequest.getUri(config)}`,
      makeRequest: () => baseRequest(config)
    };
  }

  return baseRequest(config);
};
