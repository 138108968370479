import { GlobalDispatchContext, GlobalStateContext } from '@context/GlobalContextProvider';
import { loadStripe } from '@stripe/stripe-js';
import { useContext } from 'react';

const useStripePromise = (stripeKey, stripeAccountId) => {
  const { stripePromises } = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  // Create key from the stripeKey and stripeAccountId. Used to store the promise
  // and to key the Element
  const stripePromiseKey = stripeKey && stripeAccountId && `${stripeKey}${stripeAccountId}`;

  // If we don't have a promise under that key then create one. Also we skip this
  // if we don't have the stripPromiseKey.
  if (stripePromiseKey && !stripePromises[stripePromiseKey]) {
    const newPromise = loadStripe(stripeKey, {
      stripeAccount: stripeAccountId
    });

    dispatch({ type: 'ADD_STRIPE_PROMISE', stripePromiseKey, newPromise });

    return { stripePromise: newPromise, stripePromiseKey };
  }

  // If we do have the promise then we return that otherwise we just return undefined
  // if we couldn't make anything.
  return { stripePromise: stripePromises[stripePromiseKey], stripePromiseKey };
};

export default useStripePromise;
