import { useBillingAccount } from '@context/BillingAccountContextProvider';
import { GlobalStateContext } from '@context/GlobalContextProvider';
import useCanPermissions from '@hooks/useCanPermissions';
import {
  Box,
  ButtonPrimary,
  Card,
  Flex,
  Heading,
  LoadingErrorWrapper,
  SkeletonLine,
  Text
} from '@madwire/m360ui';
import { filterDate } from '@utils/filters';
import { cdnUrl } from '@utils/global';
import { Trans, useTranslation } from 'next-i18next';
import NextLink from 'next/link';
import { useContext } from 'react';

/**
 * Constants & helpers
 */
const TRIAL_STATUS_ID = 8;
const PAID_STATUS_ID = 1;
const PAST_DUE_STATUS_ID = 2;

/**
 * Component
 */
const DashboardSubscriptions = () => {
  const { checkCan } = useCanPermissions();
  const isBillingAccount = checkCan('billing_accountGet');
  const { account } = useContext(GlobalStateContext);
  const { plans: corePlans } = account;

  const { statusId } = account;

  // current plan info
  const { billingAccount, currentPlanData, loadingCurrentPlan, currentPlanError, isTrial } =
    useBillingAccount();

  const activeCorePlan = corePlans?.find(p => p.active);
  const planName = isTrial ? activeCorePlan.plan : currentPlanData?.name;
  const { t } = useTranslation('dashboard');

  const showRenewalOrExpiration =
    (isTrial && statusId === TRIAL_STATUS_ID) ||
    statusId === PAID_STATUS_ID ||
    statusId === PAST_DUE_STATUS_ID;

  // We are deliberately presenting the next billing date as a "renewal" date here
  // because contract end dates are largely inaccurate post-migration. Also:
  // nextBillDate is a Stripe (Unix) date, so multiply by 1000 (to JS milliseconds) so
  // it can be used with the normal date formatter, since a custom format is in use
  const nextBillDate =
    billingAccount?.subscription?.invoicePreview?.subscription?.current_period_end;
  const renewalOrExprationDate = isTrial ? account.expiredDate : nextBillDate * 1000;

  return (
    <Card mx={{ mb: 3 }}>
      <Flex mx={{ justifyContent: 'space-between', mb: 6 }}>
        <Heading as="h2" type="h6">
          {t('subscriptions.title')}
        </Heading>
        {isBillingAccount && (
          <NextLink href="/settings/profile/billing/upgrade">
            <ButtonPrimary>{t('subscriptions.upgradeButton')}</ButtonPrimary>
          </NextLink>
        )}
      </Flex>
      <Card
        mx={{
          p: [9, 9],
          bg: 'neutral.20'
        }}
      >
        <LoadingErrorWrapper
          errorMessage={currentPlanError}
          isLoading={loadingCurrentPlan}
          loadingElement={<SkeletonLine mx={{ my: 1 }} />}
        >
          <Flex alignItems="center">
            <img
              src={`${cdnUrl}/m360logo.svg`}
              alt={t('subscriptions.logoAlt')}
              style={{ width: '44px', height: 'auto' }}
            />
            <Box mx={{ ml: 3 }}>
              <Text mx={{ mb: 0, fontWeight: 'semibold' }}>{planName}</Text>
              {showRenewalOrExpiration && renewalOrExprationDate && (
                <Text mx={{ mb: 0 }}>
                  <Trans
                    i18nKey={`dashboard:${
                      isTrial ? 'subscriptions.trialExpirationDate' : 'subscriptions.renewalDate'
                    }`}
                    values={{ date: filterDate(renewalOrExprationDate, 'MMMM DD, YYYY') }}
                    components={{ bold: <Text as="span" mx={{ fontWeight: 'semibold' }} /> }}
                  />
                </Text>
              )}
            </Box>
          </Flex>
        </LoadingErrorWrapper>
      </Card>
    </Card>
  );
};

export default DashboardSubscriptions;
