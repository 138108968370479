import { Task } from '@typedefs/TaskManager';
import React, { useContext } from 'react';

/**
 * Types
 */
type TaskManagerContextType = {
  tasks: Task[];
  isLoading: boolean;
  taskManagerName: string;
  tasksCount: number;
  estimatedMinutes: number;
  tasksRemaining: number;
  percentCompleted: number;
};

/**
 * Context
 */
export const TaskManagerContext = React.createContext<TaskManagerContextType>(
  {} as TaskManagerContextType
);

const useTaskManagerContextValue = (): TaskManagerContextType => {
  const contextValue = useContext(TaskManagerContext);

  if (!contextValue) {
    throw new Error('useTaskManagerContextValue must be used within a TaskManagerProvider');
  }

  return contextValue;
};

export { useTaskManagerContextValue };
