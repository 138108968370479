import { GlobalStateContext } from '@context/GlobalContextProvider';
import { AccountAssignedEmployee } from '@typedefs/Account';
import { MxType } from '@typedefs/General';
import { helpPhoneUri } from '@utils/dashboard/dashboardConsts';
import { cdnUrl, findEmployee } from '@utils/global';
import { useTranslation } from 'next-i18next';
import { useContext } from 'react';
import { openPopupWidget } from 'react-calendly';
import HelpItem from '../HelpItem';

/*
 * Types
 */
type GetDemoProps = {
  mx?: MxType;
};

/*
 *
 * Component
 *
 */
const GetDemo = ({ mx = {} }: GetDemoProps) => {
  const { t } = useTranslation('dashboard');
  const { account } = useContext(GlobalStateContext);
  const employee = findEmployee(account?.assignedEmployees) as AccountAssignedEmployee;
  const action = employee?.meetingLink
    ? () => {
        openPopupWidget({ url: employee.meetingLink as string });
      }
    : helpPhoneUri;
  return (
    <HelpItem
      imgSrc={`${cdnUrl}/dashboard/getting-started-schedule-demo-calendar.svg`}
      title={t('taskManager.helpItems.demo.title')}
      description={t('taskManager.helpItems.demo.description')}
      cta={t('taskManager.helpItems.demo.cta')}
      action={action}
      mx={mx}
    />
  );
};

export default GetDemo;
