import { apiRequest, generateDataOrParams, getApiEnvironment } from '@api/apiConfig';
import { AdsDashboardResponse } from '@typedefs/Ads';
import { ApiFunction, ApiRequestConfig } from '@typedefs/Api';
import { ContentApiResponse, ContentOverviewResponse } from '@typedefs/Content';
import { AllReportSettingsResponse } from '@typedefs/ReportSettings';
import { getAccountNumber } from '@utils/auth';

export const baseURL = `https://${getApiEnvironment()}.marketing360.com/api/reports`;

export const apiContent: ApiFunction<ContentApiResponse | ContentOverviewResponse> = ({
  url,
  method,
  payload
}: ApiRequestConfig) => {
  const baseRequest = apiRequest(baseURL);
  const dataOrParams = generateDataOrParams(method);

  const config = {
    url: `${getAccountNumber()}/${url}`,
    method,
    [dataOrParams]: payload
  };

  return baseRequest(config);
};

/**
 * Report Settings
 */

const reportSettingsUrl = (isV2: boolean) =>
  `https://${getApiEnvironment()}.marketing360.com/api/reportsettings/${isV2 ? 'v2' : 'v1'}`;

export const apiReportSettings: ApiFunction<AllReportSettingsResponse> = ({
  url,
  method,
  payload,
  isV2 = false
}) => {
  const reportSettingsRequest = apiRequest(reportSettingsUrl(isV2 as boolean));
  const dataOrParams = generateDataOrParams(method);
  return reportSettingsRequest({
    url,
    method,
    [dataOrParams]: payload
  });
};

export const apiReportSettingsV2: ApiFunction = (params: ApiRequestConfig) =>
  apiReportSettings({ ...params, isV2: true });

/**
 * Intelligence
 */
export const apiIntelligenceReporting: ApiFunction = ({ url, method, payload }) => {
  const baseRequest = apiRequest(baseURL);
  const dataOrParams = generateDataOrParams(method);
  const config = {
    url: `${getAccountNumber()}/intelligence/${url}`,
    method,
    [dataOrParams]: payload
  };

  return baseRequest(config);
};

/**
 * Ads
 */
export const apiAds: ApiFunction<AdsDashboardResponse> = ({ url, method, payload }) => {
  const baseRequest = apiRequest(baseURL);
  const dataOrParams = generateDataOrParams(method);
  const config = {
    url: `${getAccountNumber()}/ads/${url}`,
    method,
    [dataOrParams]: payload
  };
  return baseRequest(config);
};

/**
 * Page Insights
 */
export const apiInsights: ApiFunction = ({ url, method, payload }) => {
  const baseRequest = apiRequest(baseURL);
  const dataOrParams = generateDataOrParams(method);
  const config = {
    url: `${getAccountNumber()}/pageinsights/${url}`,
    method,
    [dataOrParams]: payload
  };
  return baseRequest(config);
};

/**
 * LSA Reports
 */
export const apiLsaReports: ApiFunction = ({ url, method, payload }) => {
  const baseRequest = apiRequest(baseURL);
  const dataOrParams = generateDataOrParams(method);
  const config = {
    url: `v1/${getAccountNumber()}/ads/lsa/${url}`,
    method,
    [dataOrParams]: payload
  };
  return baseRequest(config);
};
