import GettingStartedTaskManager from '@components/taskManager/GettingStartedTaskManager';
import LSATaskManager from '@components/taskManager/LSATaskManager';
import OnboardingTaskManager from '@components/taskManager/OnboardingTaskManager';
import useTaskManager from '@hooks/dashboard/useTaskManager';

const TaskManager = () => {
  const { view } = useTaskManager();

  switch (view) {
    case 'onboarding':
      return <OnboardingTaskManager />;
    case 'm360LocalSetup':
      return <LSATaskManager />;
    case 'gettingStarted':
    default:
      return <GettingStartedTaskManager />;
  }
};
export default TaskManager;
