import { Avatar, Box, ButtonPrimary, Card, Divider, Flex, Link, Text } from '@madwire/m360ui';
import { phoneNumberParser } from '@utils/phoneNumber';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import { openPopupWidget } from 'react-calendly';

const EmployeeContactCard = ({ name, phone, email, photo, hours, meetingLink, buttonText }) => {
  const { t } = useTranslation('dashboard');

  if (!name) {
    return null;
  }

  const phoneNumber = phoneNumberParser(phone);

  return (
    <>
      <Card mx={{ px: 6, pt: 7, pb: 7 }}>
        <Box mx={{ mb: 7, textAlign: 'center' }}>
          <Avatar size="lg" src={photo} />
        </Box>
        <Text mx={{ mb: 1, fontWeight: 'semibold' }}>{name}</Text>
        <Divider mx={{ my: 3 }} />
        <Text mx={{ mb: 1 }}>
          <Link href={`mailto:${email}`}>{email}</Link>
        </Text>
        <Flex mx={{ mb: 1, alignItems: 'baseline' }}>
          <Link href={phoneNumber.href}>{phoneNumber.formatted}</Link>
          <Text mx={{ ml: 2, mb: 0, color: 'text.gray', fontSize: 0, fontStyle: 'italic' }}>
            {t('employee.call-or-text').toUpperCase()}
          </Text>
        </Flex>
        <Text mx={{ mb: 0, color: 'text.gray', fontSize: 0 }}>{hours}</Text>
      </Card>
      {meetingLink && (
        <ButtonPrimary
          mx={{ mt: 5, width: '100%' }}
          onClick={() => {
            openPopupWidget({ url: meetingLink });
          }}
        >
          {buttonText}
        </ButtonPrimary>
      )}
    </>
  );
};

EmployeeContactCard.propTypes = {
  name: PropTypes.string.isRequired,
  phone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  email: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
  hours: PropTypes.string.isRequired,
  meetingLink: PropTypes.string,
  buttonText: PropTypes.string.isRequired
};

export default EmployeeContactCard;
