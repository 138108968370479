import { GlobalStateContext } from '@context/GlobalContextProvider';
import { Button, Card, Flex, Heading, Link, Text, useM360UI } from '@madwire/m360ui';
import { EmailOutlined, LocalPhoneOutlined } from '@mui/icons-material';
import { isCobrandedAgency } from '@utils/global';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import { useContext } from 'react';

const DashboardHelpCard = ({
  supportPin = null // eslint-disable-line no-unused-vars
}) => {
  const { account } = useContext(GlobalStateContext);
  const { agencyInfo } = account;
  const isCobranded = isCobrandedAgency(agencyInfo);
  const { showSupport: showAgencySupport, supportPhone, supportEmail } = agencyInfo;
  const { t } = useTranslation('dashboard');
  const title = t(`help.title${showAgencySupport ? '-cobrand' : ''}`);
  const description = t(`help.description${showAgencySupport ? '-cobrand' : '-legacy'}`);
  const { theme } = useM360UI();
  const iconStyle = { color: theme.colors.neutral[300] };
  const cobrandLinkStyle = { ml: 2 };
  return (
    <Card>
      <Heading mx={{ fontSize: 'h6' }}>{title}</Heading>
      <Text>{description}</Text>
      {isCobranded && showAgencySupport && supportEmail && supportPhone ? (
        <>
          {/* cobranded agency version: */}
          <Flex mx={{ mb: 3, alignItems: 'center' }}>
            <EmailOutlined style={iconStyle} />
            <Link href={`mailto:${supportEmail}`} mx={cobrandLinkStyle}>
              {supportEmail}
            </Link>
          </Flex>
          <Flex mx={{ alignItems: 'center' }}>
            <LocalPhoneOutlined style={iconStyle} />
            <Link mx={cobrandLinkStyle} href={`tel:${supportPhone}`}>
              {supportPhone}
            </Link>
          </Flex>
        </>
      ) : (
        <>
          {/* M360 version, also shown for cobranded agenices if they don't
              check the Show Agency Support checkbox in Backoffice agency creation/settings
          */}
          {/* Temporarily hidding until support team is ready to use this. */}
          {/* {supportPin !== null && (
            <Text mx={{ fontWeight: 'semibold' }}>{t('help.pin', { pin: supportPin })}</Text>
          )} */}
          <Link
            href="https://support.marketing360.com/hc/en-us"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button mx={{ mr: 3 }}>{t('help.help-center')}</Button>
          </Link>
          <Link
            href="https://support.marketing360.com/hc/en-us/requests/new"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button>{t('help.give-feedback')}</Button>
          </Link>
        </>
      )}
    </Card>
  );
};

DashboardHelpCard.propTypes = {
  supportPin: PropTypes.number
};

export default DashboardHelpCard;
