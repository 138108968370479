import { Heading, Text, useResponsiveValue } from '@madwire/m360ui';
import PropTypes from 'prop-types';

const DashboardHeaderText = ({ regularText, headingText }) => {
  const isMobile = useResponsiveValue([true, true, false, false]);
  return (
    <>
      <Text
        mx={{
          color: 'blue.100',
          textTransform: 'uppercase',
          marginBottom: 0,
          wordBreak: 'break-word'
        }}
      >
        {regularText}
      </Text>
      <Heading
        as="h1"
        type={isMobile ? 'h4' : 'h2'}
        mx={{
          color: 'blue.100',
          mb: [5, 10],
          wordBreak: ['break-word', 'break-word', null],
          whiteSpace: [null, null, 'nowrap', 'nowrap']
        }}
      >
        {headingText}
      </Heading>
    </>
  );
};

DashboardHeaderText.propTypes = {
  regularText: PropTypes.string.isRequired,
  headingText: PropTypes.string.isRequired
};

export default DashboardHeaderText;
