import useFetchBusinesses from 'hooks/useFetchBusinesses';
import { useTranslation } from 'next-i18next';
import { Task } from '@typedefs/TaskManager';
import useDirectConnect from 'hooks/directConnect/useDirectConnect';
import { captureException } from '@sentry/nextjs';
import useBrandAssets from '@hooks/brandProfile/useBrandAssets';
import useSocial from '@hooks/social/useSocial';
import { useBillingAccount } from '@context/BillingAccountContextProvider';
import useCrmContactCount from '@hooks/crm/useCrmContactCount';
import {
  calculateEstimatedMinutes,
  calculateTasksRemaining,
  calculatePercentCompleted
} from '@utils/dashboard/taskManagerCalculations';
import { SetupObject } from '@typedefs/Product';
import useTaskManager from '@hooks/dashboard/useTaskManager';

const DEFAULT_REPUTATION_OBJECT = {
  isComplete: false,
  currentStep: 0
};

const isLoadingData = (
  isAccountLoading: boolean,
  isLoadingBrandProfile: boolean,
  isLoadingContacts: boolean,
  isLoadingLocations: boolean,
  isLoadingSocial: boolean,
  isLoadingDirectConnect: boolean
) =>
  isAccountLoading ||
  isLoadingBrandProfile ||
  isLoadingContacts ||
  isLoadingLocations ||
  isLoadingSocial ||
  isLoadingDirectConnect;

const useOnboardingTaskManager = () => {
  const { t } = useTranslation('dashboard');
  const { accountData, loadingAccountData: isAccountLoading } = useBillingAccount();
  const {
    logos,
    colors,
    fonts,
    isLoading: isLoadingBrandProfile,
    error: brandProfileError
  } = useBrandAssets();
  const {
    contactsCount,
    isLoading: isLoadingContacts,
    error: crmContactError
  } = useCrmContactCount();
  const {
    businesses: locations,
    businessesLoading: isLoadingLocations,
    businessesError: locationsError
  } = useFetchBusinesses();
  const { socialAccounts, isLoading: isLoadingSocial, error: socialError } = useSocial();
  const {
    connections,
    loading: isLoadingDirectConnect,
    error: directConnectError
  } = useDirectConnect();
  const { onboardingTasks } = useTaskManager();

  const verifyDomainsTask = onboardingTasks?.find(task => task.id === 4);
  const approveWebsiteDesignTask = onboardingTasks?.find(task => task.id === 9);

  const getReputation = (): SetupObject => {
    if (accountData && accountData.products) {
      const reputationSetup = accountData.products.find(
        (product: { name: string }) => product.name === 'reputation'
      )?.setup;
      if (typeof reputationSetup === 'string') {
        try {
          return JSON.parse(reputationSetup);
        } catch (err) {
          captureException(err);
        }
      } else if (reputationSetup && typeof reputationSetup === 'object') {
        return reputationSetup;
      }
    }
    return DEFAULT_REPUTATION_OBJECT;
  };

  const isLoading = isLoadingData(
    isAccountLoading,
    isLoadingBrandProfile,
    isLoadingContacts,
    isLoadingLocations,
    isLoadingSocial,
    isLoadingDirectConnect
  );

  const tasks: Task[] = [
    {
      id: 0,
      title: t('taskManager.views.onboarding.tasks.uploadLogo.title'),
      description: t('taskManager.views.onboarding.tasks.uploadLogo.description'),
      minutesRequired: 2,
      // Complete if Main Logo is uploaded (type === primary)
      isComplete:
        logos?.length > 0 && !!logos.find((logo: { type: string }) => logo.type === 'primary'),
      link: `/brand-profile/assets`,
      loadingError: brandProfileError
    },
    {
      id: 1,
      title: t('taskManager.views.onboarding.tasks.selectBrandColors.title'),
      description: t('taskManager.views.onboarding.tasks.selectBrandColors.description'),
      minutesRequired: 2,
      // Complete if at least 1 color is selected
      isComplete: colors?.length > 0,
      link: `/brand-profile/assets`,
      loadingError: brandProfileError
    },
    {
      id: 2,
      title: t('taskManager.views.onboarding.tasks.selectFonts.title'),
      description: t('taskManager.views.onboarding.tasks.selectFonts.description'),
      minutesRequired: 2,
      // Complete if at least 1 font has been set
      isComplete: fonts?.length > 0,
      link: `/brand-profile/assets`,
      loadingError: brandProfileError
    },
    {
      id: 3,
      title: t('taskManager.views.onboarding.tasks.setUpContacts.title'),
      description: t('taskManager.views.onboarding.tasks.setUpContacts.description'),
      minutesRequired: 15,
      // Complete if there are at least 5 contracts
      isComplete: !!contactsCount && contactsCount >= 5,
      link: `/crm/overview`,
      loadingError: crmContactError
    },
    {
      id: 4,
      title: t('taskManager.views.onboarding.tasks.verifyDomains.title'),
      description: t('taskManager.views.onboarding.tasks.verifyDomains.description'),
      minutesRequired: 15,
      // Complete if domain is verified
      isComplete: verifyDomainsTask?.isComplete || false,
      link: `/nurture/settings/email/sending-domains`
    },
    {
      id: 5,
      title: t('taskManager.views.onboarding.tasks.addLocations.title'),
      description: t('taskManager.views.onboarding.tasks.addLocations.description'),
      minutesRequired: 1,
      // Complete if at least 1 location added
      isComplete: locations?.length > 0,
      link: `/settings/locations`,
      loadingError: locationsError
    },
    {
      id: 6,
      title: t('taskManager.views.onboarding.tasks.connectSocials.title'),
      description: t('taskManager.views.onboarding.tasks.connectSocials.description'),
      minutesRequired: 5,
      // Complete if any profile has been added
      isComplete: socialAccounts?.length > 0,
      link: `/social/setup`,
      loadingError: socialError
    },
    {
      id: 7,
      title: t('taskManager.views.onboarding.tasks.setUpReputation.title'),
      description: t('taskManager.views.onboarding.tasks.setUpReputation.description'),
      minutesRequired: 2,
      // Complete if wizard has been completed
      isComplete: getReputation().isComplete,
      isDisabled: locations?.length === 0,
      link: `/reputation/setup`
    },
    {
      id: 8,
      title: t('taskManager.views.onboarding.tasks.connectListings.title'),
      description: t('taskManager.views.onboarding.tasks.connectListings.description'),
      minutesRequired: 5,
      // TODO: Testing is blocked -> After chatting with Brett, there is a bug currently that doesn't allow us to connect correctly when we go to the link
      // Complete if both are completed
      isComplete: connections?.length === 2,
      // In Progress if one is completed
      isInProgress: connections?.length === 1,
      isDisabled: !getReputation().isComplete,
      link: `/reputation/listings`,
      loadingError: directConnectError
    },
    {
      id: 9,
      title: t('taskManager.views.onboarding.tasks.approveWebsiteDesign.title'),
      description: t('taskManager.views.onboarding.tasks.approveWebsiteDesign.description'),
      minutesRequired: 30,
      isComplete: approveWebsiteDesignTask?.isComplete || false,
      isVisible: approveWebsiteDesignTask?.isVisible || false
    }
  ];

  const tasksCount = tasks.length;
  const estimatedMinutes = calculateEstimatedMinutes(tasks);
  const tasksRemaining = calculateTasksRemaining(tasks);
  const percentCompleted = calculatePercentCompleted(tasksCount, tasksRemaining);

  return {
    tasks,
    isLoading,
    tasksCount,
    estimatedMinutes,
    tasksRemaining,
    percentCompleted
  };
};

export default useOnboardingTaskManager;
