import { externalApiCrm } from '@api/crm';
import useFetchSWR from '@hooks/useFetchSWR';
import { getAccountNumber } from '@utils/auth';

type CrmReturnType = {
  contactsCount: number;
  isLoading: boolean;
  error: string;
};

const useCrmContactCount = (): CrmReturnType => {
  const { data, isLoading, error } = useFetchSWR(
    [`contacts/count`, getAccountNumber()],
    async url =>
      (
        await externalApiCrm({
          url,
          method: 'GET'
        })
      )?.data.result[0].count
  );

  return { contactsCount: data, isLoading, error };
};

export default useCrmContactCount;
