// import DashboardContentDefault from '@components/dashboard/DashboardContentDefault';
import DashboardContactCard from '@components/dashboard/legacy/LegacyDashboardContactCard';
import DashboardHeader from '@components/dashboard/legacy/LegacyDashboardHeader';
import DashboardHelpCard from '@components/dashboard/legacy/LegacyDashboardHelpCard';
import DashboardOverviewCard from '@components/dashboard/legacy/LegacyDashboardOverviewCard';
import DashboardProductsCard from '@components/dashboard/legacy/LegacyDashboardProductsCard';
import EmailVerificationAlert from '@components/EmailVerificationAlert';
import { GlobalStateContext } from '@context/GlobalContextProvider';
import { Box, Container, Grid } from '@madwire/m360ui';
import { cdnUrl, isCobrandedAgency } from '@utils/global';
import { useTranslation } from 'next-i18next';
import { useContext, useEffect, useState } from 'react';

// If all these products are 'inactive', we know this is a Free account.
const PAID_ACCOUNT_PRODUCTS = ['ads', 'content', 'intelligence', 'social'];

// Loop though all the products and filter out the ones included in the
// 'PAID_ACCOUNT_PRODUCTS' array. If all of those products are 'inactive'
// then we ASSUME this is a Free account.
const checkFreeAccount = products =>
  products
    .filter(product => PAID_ACCOUNT_PRODUCTS.includes(product.name))
    .every(product => product.status === 'inactive');

const LegacyDashboardPage = () => {
  const { user, account } = useContext(GlobalStateContext);
  const { agencyInfo } = account;
  const isCobranded = isCobrandedAgency(agencyInfo);
  const showOverview = isCobranded ? agencyInfo.showOverview : true;
  const { t } = useTranslation('dashboard');
  const [isFreeAccount, setIsFreeAccount] = useState(false);

  useEffect(() => {
    // We need to manually determine if they have a Free account
    // based on what products they have active/inactive.
    if (account?.products) {
      const { products } = account;
      setIsFreeAccount(checkFreeAccount(products));
    }
  }, [account]);

  return (
    <Box
      mx={{
        overflow: 'hidden',
        backgroundImage: `url(${cdnUrl}/bg-graph-sharp.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: ['contain', null, 'inherit'],
        backgroundPosition: ['right top', null, 'right -200px']
      }}
    >
      <Container>
        <EmailVerificationAlert />
        <DashboardHeader
          t={t}
          account={account}
          user={user}
          isCobranded={isCobranded}
          isFreeAccount={isFreeAccount}
        />
        <Grid gap={3} columns={[1, null, null, '1fr 392px']}>
          <Box>
            <DashboardProductsCard t={t} />
          </Box>
          <Box>
            {showOverview && <DashboardOverviewCard t={t} />}
            <DashboardContactCard t={t} />
            <DashboardHelpCard supportPin={account?.supportPin} />
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default LegacyDashboardPage;
