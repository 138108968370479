import { apiPayments } from '@api/payments';
import { GlobalStateContext } from '@context/GlobalContextProvider';
import useFetchData from '@hooks/useFetchData';
import useStripePromise from '@hooks/useStripePromise';
import PropTypes from 'prop-types';
import { useCallback, useContext } from 'react';

const usePayments = () => {
  const {
    account: { accountNumber }
  } = useContext(GlobalStateContext);

  // ----- Fetch booking data ----- //
  const paymentQuery = useCallback(
    async () =>
      apiPayments({
        method: 'GET',
        url: `api/account`,
        headers: {
          'Marketing360-Account': accountNumber
        }
      }),
    [accountNumber]
  );
  const { loading, response, error } = useFetchData({ query: paymentQuery });
  const paymentsData = response?.data;

  const { stripePromise, stripePromiseKey } = useStripePromise(
    paymentsData?.stripeKey,
    paymentsData?.stripeAccountId
  );

  return {
    lookupCustomer: async payload =>
      apiPayments({
        method: 'GET',
        url: `stripe/v1/customers`,
        headers: {
          'Marketing360-Account': accountNumber
        },
        payload
      }),
    createCustomer: async payload =>
      apiPayments({
        method: 'POST',
        url: 'stripe/v1/customers',
        headers: {
          'Marketing360-Account': accountNumber
        },
        payload
      }),
    createPaymentIntent: async ({ payload, sourceName }) => {
      const keyIndex = Object.values(paymentsData.Sources).findIndex(
        source => source === sourceName
      );
      const sourceID = Object.keys(paymentsData.Sources)[keyIndex];

      return apiPayments({
        method: 'POST',
        url: 'stripe/v1/payment_intents',
        headers: {
          'Marketing360-Account': accountNumber,
          'Marketing360-Payments-Source': sourceID
        },
        payload: {
          ...payload,
          currency: 'usd',
          setup_future_usage: 'off_session'
        }
      });
    },
    stripePromise,
    stripePromiseKey,
    onboardingStatus: paymentsData?.onboardingStatus,
    loading,
    error
  };
};

usePayments.propTypes = { m360ID: PropTypes.number };

export default usePayments;
