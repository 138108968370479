import {
  Box,
  Grid,
  LoadingErrorWrapper,
  SkeletonParagraph,
  useResponsiveValue
} from '@madwire/m360ui';
import { getLocale } from '@utils/global';
import moment from 'moment';
import { useTranslation } from 'next-i18next';
import useCrmContactCount from '@hooks/crm/useCrmContactCount';
import PropTypes from 'prop-types';
import DashboardHeaderText from './DashboardHeaderText';

const getContactMessage = num => {
  if (num === 0) return 'howManyContacts_zero';
  if (num === 1) return 'howManyContacts';
  return 'howManyContacts_plural';
};

const getGreetingMessage = date => {
  const time = date.hour();
  if (time > 4 && time < 12) return 'goodMorning';
  if (time >= 12 && time < 17) return 'goodAfternoon';
  return 'goodEvening';
};

const DashboardHeader = ({ user }) => {
  const isMobile = useResponsiveValue([true, true, true, false]);
  const { t } = useTranslation('dashboard');
  const locale = getLocale();
  const currentDate = moment();
  const { contactsCount, isLoading: isLoadingContacts, error } = useCrmContactCount();

  return (
    <Grid gap={3} columns={[1, null, null, '2fr 1fr']}>
      <Box>
        <DashboardHeaderText
          regularText={`${t('dashboard.textBeforeDate')} ${currentDate.format('dddd, MMMM Do')}`}
          headingText={
            user
              ? t(`dashboard.${getGreetingMessage(currentDate)}`, {
                  firstName: user.firstName
                })
              : t('dashboard.title-alt')
          }
        />
      </Box>

      {!isMobile && !error && (
        <Box>
          <LoadingErrorWrapper
            isLoading={isLoadingContacts}
            loadingElement={<SkeletonParagraph numberOfLines={2} />}
          >
            <DashboardHeaderText
              regularText={t('dashboard.contacts')}
              headingText={t(`dashboard.${getContactMessage(contactsCount)}`, {
                count: new Intl.NumberFormat(locale).format(contactsCount)
              })}
            />
          </LoadingErrorWrapper>
        </Box>
      )}
    </Grid>
  );
};

DashboardHeader.propTypes = {
  user: PropTypes.object
};

export default DashboardHeader;
