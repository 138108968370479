/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useTaskManagerContextValue } from '@context/TaskManagerContextProvider';
import { Flex, Progress, Tag, Text, useResponsiveValue } from '@madwire/m360ui';
import { useTranslation } from 'next-i18next';

/*
 *
 * Component
 *
 */
const TaskProgress = () => {
  const { t } = useTranslation('dashboard');
  const showTimeEstimate = useResponsiveValue([false, true]);
  const { estimatedMinutes, percentCompleted, tasksRemaining } = useTaskManagerContextValue();
  return (
    <>
      <Progress value={percentCompleted / 100} mx={{ height: 8, mb: 5 }} />
      {/* @ts-ignore */}
      <Flex alignItems="center">
        <Tag color="blue" mx={{ height: 24, textTransform: 'none' }}>
          {t('taskManager.tasksRemaining', { count: tasksRemaining })}
        </Tag>
        {showTimeEstimate && (
          /* @ts-ignore */
          <Text mx={{ ml: 3, mr: 3, mb: 0, fontSize: 12 }}>
            {t('taskManager.minutesEstimate', { count: estimatedMinutes })}
          </Text>
        )}
        {/* @ts-ignore */}
        <Text mx={{ ml: 'auto', mb: 0, fontSize: 12, fontWeight: 'semibold' }}>
          {t('taskManager.percentCompleted', { percentCompleted })}
        </Text>
      </Flex>
    </>
  );
};

export default TaskProgress;
