import { Flex, Text } from '@madwire/m360ui';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

const LegacyProductTitle = ({ productKey, extraText }) => {
  const { t } = useTranslation('common');

  return (
    <Flex mx={{ alignItems: 'center' }}>
      <Text
        mx={{
          mb: 0,
          fontSize: 2,
          fontWeight: 'semibold'
        }}
      >
        {t(`products.${productKey}.title`)}
      </Text>
      {extraText && <Text mx={{ mb: 0 }}>{extraText}</Text>}
    </Flex>
  );
};

LegacyProductTitle.propTypes = {
  productKey: PropTypes.string.isRequired,
  extraText: PropTypes.string
};

export default LegacyProductTitle;
