import ModalWrapper from '@components/modals/ModalWrapper';
import { Box, ButtonLink, Card, Embed, Grid, Heading, Image, Text } from '@madwire/m360ui';
import { cdnUrl } from '@utils/global';
import PropTypes from 'prop-types';

const DashboardOverviewCard = ({ t }) => (
  <Card mx={{ mb: 3 }}>
    <Heading mx={{ fontSize: 'h6' }}>{t('overview.title')}</Heading>
    <Grid columns="108px 1fr">
      <Box>
        <ModalWrapper
          title={t('overview.title')}
          renderOpener={({ handleOpenModal }) => (
            <ButtonLink mx={{ p: 0, height: 'auto' }} onClick={handleOpenModal}>
              <Image src={`${cdnUrl}/video-overview.png`} alt={t('overview.title')} />
            </ButtonLink>
          )}
          renderComponent={() => (
            <Embed
              title={t('overview.title')}
              src="https://player.vimeo.com/video/250147301?autoplay=1"
              allow="autoplay; fullscreen"
            />
          )}
        />
      </Box>
      <Text>{t('overview.description')}</Text>
    </Grid>
  </Card>
);

DashboardOverviewCard.propTypes = {
  t: PropTypes.func.isRequired
};

export default DashboardOverviewCard;
