import DashboardTipsAndTricksModal from '@components/dashboard/DashboardTipsAndTricksModal';
import useModalManager from '@hooks/useModalManager';
import { MxType } from '@typedefs/General';
import { cdnUrl } from '@utils/global';
import { useTranslation } from 'next-i18next';
import HelpItem from '../HelpItem';

/*
 * Constants
 */
type IntroVideoProps = {
  mx?: MxType;
};

/*
 *
 * Component
 *
 */
const IntroVideo = ({ mx = {} }: IntroVideoProps) => {
  const { t } = useTranslation('dashboard');
  const { openModal } = useModalManager();
  return (
    <HelpItem
      imgSrc={`${cdnUrl}/dashboard/getting-started-video-thumb.jpg`}
      title={t('taskManager.helpItems.video.title')}
      description={t('taskManager.helpItems.video.description')}
      cta={t('taskManager.helpItems.video.cta')}
      action={() => openModal(<DashboardTipsAndTricksModal />)}
      mx={mx}
    />
  );
};

export default IntroVideo;
