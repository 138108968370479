/* eslint-disable @typescript-eslint/ban-ts-comment */
import useEmailVerify from '@hooks/useEmailVerify';
import { AlertWarning, Box, ButtonLink } from '@madwire/m360ui';
import { useTranslation } from 'next-i18next';

const EmailVerificationAlert: React.FC = () => {
  const { nonVerifiedEmail, isVerified, resendEmail } = useEmailVerify();
  const { t } = useTranslation('common');
  return isVerified ? null : (
    <AlertWarning
      mx={{
        mb: 7
      }}
      message={
        // @ts-ignore
        <Box mx={{ wordBreak: 'break-word' }}>
          {t('verify-email.description', {
            email: nonVerifiedEmail?.email || t('verify-email.yourEmail')
          })}{' '}
          {/* @ts-ignore */}
          <ButtonLink mx={{ p: 0, height: 'auto', fontWeight: 'normal' }} onClick={resendEmail}>
            {t('verify-email.resend')}
          </ButtonLink>
        </Box>
      }
    />
  );
};

export default EmailVerificationAlert;
