import { useMemo } from 'react';
import { EAM_SOCIAL_PRODUCTS } from '@utils/settings/link-accounts';
import useFetchSWR from '@hooks/useFetchSWR';
import { apiDirectConnect } from '@api/direct-connect';
import useDirectLocationRequest from './useDirectLocationRequest';
import useDirectRequest from './useDirectRequest';
import { getAccountNumber } from '@utils/auth';

const useDirectConnect = id => {
  const accountId = getAccountNumber();

  const GOOGLE_PRODUCT = EAM_SOCIAL_PRODUCTS[2];
  const FACEBOOK_PRODUCT = EAM_SOCIAL_PRODUCTS[0];

  const {
    googleDirectConnect,
    facebookDirectConnect,
    loading: directConnectLoading,
    error: directConnectError,
    mutate: directConnectMutate
  } = useDirectRequest();

  const {
    locationData = [],
    error: locationError,
    loading: locationLoading,
    mutate: locationMutate
  } = useDirectLocationRequest();

  const googleData = useMemo(
    () => ({
      socialInfo: GOOGLE_PRODUCT,
      directConnect: googleDirectConnect,
      connectedLocation: locationData.find(
        location =>
          location.locationID === parseInt(id) &&
          location.directConnect.publisherID === GOOGLE_PRODUCT.publisherID
      )
    }),
    [GOOGLE_PRODUCT, googleDirectConnect, id, locationData]
  );

  const facebookData = useMemo(
    () => ({
      socialInfo: FACEBOOK_PRODUCT,
      directConnect: facebookDirectConnect,
      connectedLocation: locationData.find(
        location =>
          location.locationID === parseInt(id) &&
          location.directConnect.publisherID === FACEBOOK_PRODUCT.publisherID
      )
    }),
    [FACEBOOK_PRODUCT, facebookDirectConnect, id, locationData]
  );

  const { data, isLoading, error } = useFetchSWR(
    accountId ? `/direct-connect?accountID=${accountId}` : null,
    async url =>
      (
        await apiDirectConnect({
          url,
          method: 'get'
        })
      )?.data
  );

  return {
    connections: data,
    googleData,
    facebookData,
    locationMutate,
    directConnectMutate,
    connectedLocations: locationData,
    loading: directConnectLoading || locationLoading || isLoading,
    error: directConnectError || locationError || error
  };
};

export default useDirectConnect;
