import TaskManagerUI from '@components/taskManager/TaskManagerUI';
import { TaskManagerContext } from '@context/TaskManagerContextProvider';
import useGettingStartedTaskManager from '@hooks/dashboard/useGettingStartedTaskManager';
import { LoadingErrorWrapper } from '@madwire/m360ui';
import { useMemo } from 'react';

const GettingStartedTaskManager = () => {
  const { tasks, isLoading, tasksCount, estimatedMinutes, tasksRemaining, percentCompleted } =
    useGettingStartedTaskManager();

  const value = useMemo(
    () => ({
      taskManagerName: 'gettingStarted',
      tasks,
      isLoading,
      tasksCount,
      estimatedMinutes,
      tasksRemaining,
      percentCompleted
    }),
    [estimatedMinutes, isLoading, percentCompleted, tasks, tasksCount, tasksRemaining]
  );

  return (
    <TaskManagerContext.Provider value={value}>
      <LoadingErrorWrapper isLoading={isLoading}>
        <TaskManagerUI />
      </LoadingErrorWrapper>
    </TaskManagerContext.Provider>
  );
};

export default GettingStartedTaskManager;
