import DesktopLayout from '@components/taskManager/DesktopLayout';
import MobileLayout from '@components/taskManager/MobileLayout';
import { useTaskManagerContextValue } from '@context/TaskManagerContextProvider';
import useTaskManager from '@hooks/dashboard/useTaskManager';
import {
  Card,
  IconButton,
  LoadingErrorWrapper,
  useM360UI,
  useResponsiveValue
} from '@madwire/m360ui';
import { ExpandMore } from '@mui/icons-material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const TaskManagerUI = () => {
  const { t } = useTranslation('dashboard');
  const { isLoadingTaskManagerStatus } = useTaskManager();
  const { theme } = useM360UI();
  const isMobile = useResponsiveValue([true, true, true, false]);
  const [isOpen, setIsOpen] = useState(true);
  const { isLoading } = useTaskManagerContextValue();

  return (
    <Card
      mx={{
        position: 'relative',
        mb: isMobile ? 5 : 7,
        pt: [7, 7, 7, 7],
        pb: [7, 7, 7, 0]
      }}
    >
      <LoadingErrorWrapper isLoading={isLoadingTaskManagerStatus || isLoading}>
        {/* absolutely-positioned toggle button for the collapse */}
        <IconButton
          mx={{
            p: 0,
            position: 'absolute',
            top: theme.space[6],
            right: theme.space[8],
            zIndex: 1,
            transform: isOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
            transition: 'transform',
            '&:hover, &:active, &:focus': {
              background: 'transparent'
            }
          }}
          label={t('common:actions.toggle-collapse')}
          onClick={() => {
            setIsOpen(current => !current);
          }}
        >
          <ExpandMore />
        </IconButton>
        {isMobile ? <MobileLayout isOpen={isOpen} /> : <DesktopLayout isOpen={isOpen} />}
      </LoadingErrorWrapper>
    </Card>
  );
};

export default TaskManagerUI;
