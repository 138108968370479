import { getAccountNumber } from '@utils/auth';
import { apiRequest, generateDataOrParams, getApiEnvironment } from './apiConfig';

const baseURL = `https://${getApiEnvironment()}.marketing360.com/api/lsa/v1`;

// Service for managing Google Local Service Ads.
export const apiLsa = ({ url, method, payload, cache = false }) => {
  const baseRequest = apiRequest(baseURL);
  const dataOrParams = generateDataOrParams(method);
  return baseRequest({
    url: `${getAccountNumber()}/${url}`,
    method,
    [dataOrParams]: payload,
    cache
  });
};

/**
 *  *Usage*
 * /verifications/{requestId}/link
 * /verifications/{requestId}/restart
 */
export const apiGetEvidentLink = ({ url, method, cache = false }) => {
  const baseRequest = apiRequest(baseURL);
  return baseRequest({
    url: `${getAccountNumber()}/${url}`,
    method,
    cache
  });
};
