import EmployeeContactCard from '@components/EmployeeContactCard';
import { GlobalStateContext } from '@context/GlobalContextProvider';
import { Card, Heading, Text } from '@madwire/m360ui';
import { findEmployee, MARKETING_CONSULTANT } from '@utils/global';
import PropTypes from 'prop-types';
import { useContext } from 'react';

const DashboardContactCard = ({ t }) => {
  const { account } = useContext(GlobalStateContext);

  const employee = findEmployee(account?.assignedEmployees);

  if (!employee) {
    return null;
  }

  let title = t('employee.success-manager.title');
  let description = t('employee.success-manager.description');
  let buttonText = t('employee.success-manager.cta');

  if (employee?.title === MARKETING_CONSULTANT) {
    title = t('employee.marketing-consultant.title');
    description = t('employee.marketing-consultant.description');
    buttonText = t('employee.marketing-consultant.cta');
  }

  return (
    <Card mx={{ mb: 3 }}>
      <Heading mx={{ fontSize: 'h6' }}>{title}</Heading>
      <Text>{description}</Text>
      {employee && (
        <EmployeeContactCard
          t={t}
          name={employee.name}
          phone={employee.phone}
          email={employee.email}
          photo={employee.photo}
          title={employee.title}
          hours={employee.hours}
          meetingLink={employee.meetingLink}
          buttonText={buttonText}
        />
      )}
    </Card>
  );
};

DashboardContactCard.propTypes = {
  t: PropTypes.func.isRequired
};

export default DashboardContactCard;
