const { useM360UI, Box } = require('@madwire/m360ui');

const DashboardBackground = () => {
  const { theme } = useM360UI();
  return (
    <Box
      mx={{
        backgroundColor: theme.colors.cta.primary,
        minHeight: 300,
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <Box
        mx={{
          position: 'absolute',
          left: '76.58%',
          right: '-40.06%',
          top: '-200%',
          bottom: '82.15%',
          background: `linear-gradient(284.49deg, ${theme.colors.blue[300]} 29.13%, ${theme.colors.blue.dark} 60.75%)`,
          opacity: 0.17,
          transform: 'matrix(0.71, 0.71, -0.71, 0.71, 0, 0)',
          height: 1199.59,
          width: 1199.59
        }}
      />
      <Box
        mx={{
          position: 'absolute',
          left: '65.81%',
          right: '-93.29%',
          top: '-59.58%',
          bottom: '-15.7%',
          background: `linear-gradient(284.49deg, ${theme.colors.blue[300]} 29.13%, ${theme.colors.blue.dark} 60.75%)`,
          opacity: 0.17,
          transform: 'matrix(-0.71, 0.71, -0.71, -0.71, 0, 0)',
          height: 1199.59,
          width: 1199.59
        }}
      />
    </Box>
  );
};

export default DashboardBackground;
