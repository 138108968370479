import { Box } from '@madwire/m360ui';

/**
 * Taken from https://github.com/spacecraftinc/m360-navigation-bar/blob/master/src/components/icons/Business.jsx
 * Adjusted styles to match Dashboard mockups
 */

const BusinessIcon = () => (
  <Box
    mx={{
      color: 'neutral.200',
      lineHeight: 0
    }}
  >
    <svg
      className="business-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="currentcolor"
    >
      <path
        d="M22.2,9.5l.75,3.75H5.55L6.3,9.5H22.2M24.25,7h-20L3,13.25v2.5H4.25v7.5h12.5v-7.5h5v7.5h2.5v-7.5H25.5v-2.5ZM6.75,20.75v-5h7.5v5Z"
        transform="translate(0.75 1.75)"
      />
      <g className="g-nav__svg--flipOnly">
        <path d="M24,4H4V6.5H24Z" transform="translate(1 1)" />
      </g>
      <style jsx>{`
        .business-icon {
          transition: fill 0.3s, transform 0.3s, opacity 0.3s;
        }
        .business-icon:hover {
          opacity: 1;
        }
        .g-nav__svg--flipOnly {
          transition: transform 0.3s;
          transform-origin: 50% 50%;
          transform: rotateY(0);
        }

        .business-icon:hover > .g-nav__svg--flipOnly {
          transform: rotateY(180deg);
        }
      `}</style>
    </svg>
  </Box>
);

export default BusinessIcon;
