import { Task } from '@typedefs/TaskManager';

export const calculateEstimatedMinutes = (tasksData: Task[]) =>
  tasksData.reduce(
    (accum, item) =>
      accum + (item.isComplete || item.isVisible === false ? 0 : item.minutesRequired),
    0
  );

export const calculateTasksRemaining = (tasksData: Task[]) =>
  tasksData.reduce(
    (accum, item) => accum + (item.isComplete || item.isVisible === false ? 0 : 1),
    0
  );

export const calculatePercentCompleted = (tasksCount: number, tasksRemaining: number) =>
  ((tasksCount - tasksRemaining) / tasksCount) * 100;
