import { getAccountNumber } from '@utils/auth';
import { apiRequest, generateDataOrParams, getApiEnvironment } from './apiConfig';

export const baseURL = `https://${getApiEnvironment()}.marketing360.com/api/eam/`;

export const apiEamLogin = ({ platform, method, payload, isV2 }) => {
  const baseRequest = apiRequest(baseURL);
  const dataOrParams = generateDataOrParams(method);
  return baseRequest({
    url: isV2
      ? `v2/${getAccountNumber()}/${platform}/loginurl`
      : `v1/loginurl/${platform}/${getAccountNumber()}`,
    method,
    [dataOrParams]: payload,
    cache: false
  });
};

export const apiEamIntegrationsUpdate = ({ url, method, payload }) => {
  const baseRequest = apiRequest(baseURL);
  const dataOrParams = generateDataOrParams(method);
  return baseRequest({
    url: `v2/${getAccountNumber()}/${url}`,
    method,
    [dataOrParams]: payload
  });
};

/**
 * @param {string} platform - must be 'facebook', 'google', 'bing'
 * @param {object} payload - { app: 'string', identifier: 'string'  }
 * @returns {Promise}
 * @description - Deletes a token for a given platform
 */
export const apiEamIntegrationDeleteToken = ({ platform, payload }) => {
  const baseRequest = apiRequest(baseURL);
  return baseRequest({
    url: `v2/${getAccountNumber()}/${platform}/token`,
    method: 'delete',
    params: payload
  });
};
