/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Reducer } from 'react';
import { handleErrorMessage } from './global';

/**
 * Types
 */
export type BaseActions =
  | { type: 'LOADING'; loading: boolean }
  | { type: 'LOADING_SECONDARY'; loading: boolean }
  | { type: 'LOADING_SUBMIT'; loading: boolean }
  | { type: 'ERROR'; error: unknown };

/**
 * Utils
 */
export const defaultSwitchCase = (action?: { type: string } | null) => {
  if (!action) {
    throw new Error(`Invalid Action Type`);
  } else {
    throw new Error(`Invalid Action Type: ${action.type}`);
  }
};

export const withBaseReducer =
  <S, A>(incomingReducer: Reducer<S, A>) =>
  (state: S, action: A | BaseActions) => {
    // @ts-ignore
    switch (action.type) {
      case 'LOADING':
        return {
          ...state,
          // @ts-ignore
          loading: action.loading
        };
      case 'LOADING_SECONDARY':
        return {
          ...state,
          // @ts-ignore
          loadingSecondary: action.loading
        };
      case 'LOADING_SUBMIT':
        return {
          ...state,
          // @ts-ignore
          loadingSubmit: action.loading
        };
      case 'ERROR':
        return {
          ...state,
          loading: false,
          loadingSecondary: false,
          loadingSubmit: false,
          // @ts-ignore
          error: handleErrorMessage(action.error)
        };
      default:
        return incomingReducer(state, action as A);
    }
  };
