import { Box, ButtonPrimary, Flex, Heading, Link, Text } from '@madwire/m360ui';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import BusinessIcon from '../BusinessIcon';

const DashboardHeader = ({ t, account, user, isFreeAccount, isCobranded }) => (
  <Flex
    mx={{
      alignItems: [null, null, 'center'],
      justifyContent: 'space-between',
      flexDirection: ['column', null, 'row'],
      mb: 4
    }}
  >
    <Box>
      {account && (
        <Flex mx={{ alignItems: 'center', mb: 2, display: [null, null, 'none', 'none'] }}>
          <BusinessIcon />
          <Text mx={{ ml: 3, mb: 0 }}>
            {account.externalAccountNumber && `${account.externalAccountNumber} -`}{' '}
            {account.displayName}
          </Text>
        </Flex>
      )}
      <Heading as="h1" type="h2" mx={{ mb: [1, null, 2] }}>
        {user
          ? t('dashboard.title', {
              firstName: user.firstName
            })
          : t('dashboard.title-alt')}
      </Heading>
    </Box>
    {isFreeAccount && !isCobranded && (
      <Box>
        <NextLink href="/plans-pricing" passHref>
          <Link>
            <ButtonPrimary>{t('dashboard.plans-pricing')}</ButtonPrimary>
          </Link>
        </NextLink>
      </Box>
    )}
  </Flex>
);

DashboardHeader.propTypes = {
  t: PropTypes.func.isRequired,
  account: PropTypes.object,
  user: PropTypes.object,
  isCobranded: PropTypes.bool,
  isFreeAccount: PropTypes.bool
};

export default DashboardHeader;
