import { apiReportSettingsV2 } from '@api/reporting';
import useFetchSWR from '@hooks/useFetchSWR';
import { getAccountNumber } from '@utils/auth';

type SocialSetUpData = {
  accountId: string;
  platform: string;
};

type SocialReturnType = {
  socialAccounts: SocialSetUpData[];
  isLoading: boolean;
  error: string;
};

const useSocial = (): SocialReturnType => {
  const accountId = getAccountNumber();

  const { data, isLoading, error } = useFetchSWR(
    accountId ? `/${accountId}/social/pages` : null,
    async url =>
      (
        await apiReportSettingsV2({
          url,
          method: 'get'
        })
      )?.data.response
  );
  return { socialAccounts: data as SocialSetUpData[], isLoading, error };
};

export default useSocial;
