import { apiLsa } from '@api/lsa';
import { GlobalStateContext } from '@context/GlobalContextProvider';
import useFetchSWR from '@hooks/useFetchSWR';
import usePayments from '@hooks/usePayments';
import { AccountStatusEnum } from '@typedefs/AccountStatus';
import { Location } from '@typedefs/LSA';
import { Task } from '@typedefs/TaskManager';
import {
  calculateEstimatedMinutes,
  calculateTasksRemaining,
  calculatePercentCompleted
} from '@utils/dashboard/taskManagerCalculations';
import { CONFIG } from '@utils/environment';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const isLoadingData = (isLoadingPayments: boolean, isLoadingLocations: boolean) =>
  isLoadingPayments || isLoadingLocations;

const useLSATaskManager = () => {
  const { t } = useTranslation('dashboard');
  const { loading: isLoadingPayments, onboardingStatus, error: paymentsError } = usePayments();
  const { account } = useContext(GlobalStateContext);

  const {
    data: locations,
    loading: isLoadingLocations,
    error: lsaLocationsError
  } = useFetchSWR('locations', async () => {
    const { data } = await apiLsa({
      url: 'locations',
      method: 'GET',
      payload: {}
    });
    return data.response;
  });

  const isLoading = isLoadingData(isLoadingPayments, isLoadingLocations);

  const isStepOneComplete = Boolean(
    account.statusId === AccountStatusEnum.Paid ||
      account.statusId === AccountStatusEnum['Past Due']
  );

  const isStepTwoComplete =
    locations?.length > 0 &&
    !!locations.find(
      (location: Location) =>
        location.progress.businessVerification === 'complete' ||
        location.progress.businessVerification === 'passed' ||
        location.progress.businessVerification === 'connected'
    );

  const isStepTwoInProgress =
    locations?.length > 0 &&
    !!locations.find(
      (location: Location) =>
        location.progress.businessVerification === 'deleted' ||
        location.progress.businessVerification === 'failed' ||
        location.progress.businessVerification === 'paused' ||
        location.progress.businessVerification === 'pending' ||
        location.progress.businessVerification === 'payment_required' ||
        location.progress.businessVerification === 'waiting'
    );

  const isStepThreeComplete =
    locations?.length > 0 &&
    !!locations.find((location: Location) => location.adAccountBudget !== null);

  const locationId =
    (isStepTwoComplete || isStepTwoInProgress) &&
    locations.find((location: Location) => location.locationId !== null).locationId;

  const tasks: Task[] = [
    {
      id: 0,
      title: t('taskManager.tasks.purchaseYourPlan.title'),
      description: t('taskManager.tasks.purchaseYourPlan.description'),
      minutesRequired: 3,
      isComplete: isStepOneComplete,
      link: '/settings/profile/billing/upgrade',
      loadingError: lsaLocationsError
    },
    {
      id: 1,
      title: t('taskManager.tasks.verifyBusiness.title'),
      description: t('taskManager.tasks.verifyBusiness.description'),
      minutesRequired: 3,
      isComplete: isStepTwoComplete,
      isInProgress: !isStepTwoComplete && isStepTwoInProgress,
      link: '/ads/local-service-ads/verification',
      isDisabled: !isStepOneComplete,
      loadingError: lsaLocationsError
    },
    {
      id: 2,
      title: t('taskManager.tasks.setYourAdsBudget.title'),
      description: t('taskManager.tasks.setYourAdsBudget.description'),
      minutesRequired: 5,
      isComplete: isStepThreeComplete,
      link: `/ads/local-service-ads/verification?acccount/new/${locationId}`,
      isDisabled: !isStepTwoComplete && !isStepTwoInProgress,
      loadingError: lsaLocationsError
    },
    {
      id: 3,
      title: t('taskManager.tasks.setUpPayments.title'),
      description: t('taskManager.tasks.setUpPayments.description'),
      minutesRequired: 15,
      isComplete: Boolean(onboardingStatus === 3 || onboardingStatus === 4),
      isInProgress: Boolean(onboardingStatus === 2),
      link: CONFIG.paymentsUrl,
      loadingError: paymentsError
    }
  ];

  const tasksCount = tasks.length;
  const estimatedMinutes = calculateEstimatedMinutes(tasks);
  const tasksRemaining = calculateTasksRemaining(tasks);
  const percentCompleted = calculatePercentCompleted(tasksCount, tasksRemaining);

  return {
    tasks,
    isLoading,
    tasksCount,
    estimatedMinutes,
    tasksRemaining,
    percentCompleted
  };
};

export default useLSATaskManager;
