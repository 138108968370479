/**
 * Parses business information down to essential items for global state
 * @param {Any[]} data Array of businesses response data
 */
export const simplifyBusiness = data =>
  data?.map(business => ({
    id: business.business_id,
    name: business.business_name,
    address: business.address,
    city: business.city,
    state: business.region_short,
    slug: business.slug,
    logo: business.logo,
    postal_code: business.postal_code,
    status: business.status
  }));
