import { getAccountNumber } from '@utils/auth';
import { RawAxiosRequestHeaders } from 'axios';
import { apiRequest, generateDataOrParams, getApiEnvironment } from './apiConfig';

export const bpBaseUrl = `https://${getApiEnvironment()}.marketing360.com/api/brandprofile/v2`;

type ApiBrandProfileParams = {
  cache?: boolean;
  method: string;
  payload?: Record<string, unknown> | Record<string, unknown>[] | FormData;
  url?: string;
  headers?: RawAxiosRequestHeaders;
};
export const apiBrandProfile = ({
  url,
  method,
  payload = undefined,
  cache = false,
  headers = {}
}: ApiBrandProfileParams) => {
  const baseRequest = apiRequest(`${bpBaseUrl}`);
  const dataOrParams = generateDataOrParams(method);
  return baseRequest({
    url: `${getAccountNumber()}/${url}`,
    method,
    [dataOrParams]: payload,
    cache,
    headers
  });
};

type ApiBrandLogoUploadParams = {
  method: string;
  payload: unknown;
  url?: string;
};
export const apiBrandLogoUpload = ({ url = '', payload, method }: ApiBrandLogoUploadParams) => {
  const baseRequest = apiRequest(`${bpBaseUrl}`);
  const dataOrParams = generateDataOrParams(method);
  return baseRequest({
    url: `${getAccountNumber()}/assets/logos${url ? `/${url}` : ''}`,
    method,
    [dataOrParams]: payload,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  });
};
