/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Collapse, Flex, useM360UI } from '@madwire/m360ui';
import { helpItemBaseHeight } from '@utils/dashboard/dashboardConsts';
import { useTaskManagerContextValue } from '@context/TaskManagerContextProvider';
import TaskProgress from './TaskProgress';
import Tasks from './Tasks';
import Title from './Title';
import GetDemo from './HelpItems/GetDemo';
import IntroVideo from './HelpItems/IntroVideo';
import MCCard from './HelpItems/MCCard';

/*
 * Constants
 */
const rightColumnWidth = 360;

/*
 * Types
 */
type DesktopLayoutProps = {
  isOpen: boolean;
};

/*
 *
 * Component
 *
 */
const DesktopLayout = ({ isOpen }: DesktopLayoutProps) => {
  const { theme } = useM360UI();
  const { tasksRemaining } = useTaskManagerContextValue();

  const floatingItemMx = {
    width: rightColumnWidth,
    position: 'absolute',
    transition: 'transform 0.2s ease-in-out',
    transform: isOpen
      ? `translate(0px, 0px)`
      : `translate(-${theme.space[6]}px, -${theme.space[10]}px)`
  };

  return (
    // @ts-ignore
    <Flex mx={{ width: 'full', gap: 8, mb: tasksRemaining <= 0 ? 7 : 0 }}>
      {/* layout box that wraps all the left-side desktop content */}
      {/* @ts-ignore */}
      <Box mx={{ flex: 1 }}>
        {/* layout box that manages the open-state-only bleed-to-left border below progress info */}
        {/* @ts-ignore */}
        <Box
          mx={{
            flex: 1,
            ml: -9,
            pl: 9,
            borderStyle: 'solid',
            borderWidth: `0 0 ${theme.borderWidths[1]} 0`,
            borderColor: isOpen ? 'component.border' : 'transparent'
          }}
        >
          <Title />
          {/* layout box that manages the open-state-only right-side border of progress info */}
          {/* @ts-ignore */}
          <Box
            mx={{
              pt: 5,
              pb: 7,
              pr: 7,
              borderStyle: 'solid',
              borderWidth: `0 ${theme.borderWidths[1]} 0 0`,
              borderColor: isOpen ? 'component.border' : 'transparent'
            }}
          >
            <TaskProgress />
          </Box>
        </Box>
        {/* collapse #1, for tasks list */}
        <Collapse isOpen={isOpen}>
          {/* layout box that manages the open-state-only right-side border of tasks list */}
          {/* @ts-ignore */}
          <Flex
            mx={{
              flex: 1,
              gap: 5,
              borderStyle: 'solid',
              borderWidth: `0 ${theme.borderWidths[1]} 0 0`,
              borderColor: 'component.border',
              pt: 7,
              pr: 7,
              pb: 7
            }}
            flexDirection="column"
          >
            <Tasks />
          </Flex>
        </Collapse>
      </Box>
      {/* collapse #2, for demo/video/contact content */}
      <Collapse isOpen={isOpen} startingHeight={helpItemBaseHeight}>
        {/* demo/video/contact (DashboardGettingStartedHelpItems) container */}
        {/* @ts-ignore */}
        <Flex mx={{ width: rightColumnWidth, gap: 7, mt: 11 }} flexDirection="column">
          <MCCard mx={floatingItemMx} />
          <GetDemo mx={{ mt: helpItemBaseHeight + theme.space[7] }} />
          <IntroVideo />
        </Flex>
      </Collapse>
    </Flex>
  );
};

export default DesktopLayout;
