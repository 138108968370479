import TipsAndTricksCard from '@components/TipsAndTricksCard';
import useModalManager from '@hooks/useModalManager';
import { ButtonLink, Image } from '@madwire/m360ui';
import { cdnUrl } from '@utils/global';
import { useTranslation } from 'next-i18next';
import DashboardTipsAndTricksModal from './DashboardTipsAndTricksModal';

const DashboardOverviewCard = () => {
  const { t } = useTranslation('dashboard');
  const { openModal } = useModalManager();
  return (
    <TipsAndTricksCard
      title={t('dashboard.tipsAndTricks')}
      videoButton={
        <ButtonLink
          mx={{ p: 0, height: 'auto' }}
          onClick={() => openModal(<DashboardTipsAndTricksModal />)}
        >
          <Image src={`${cdnUrl}/video-overview.png`} alt={t('tipsAndTricks.videoTitle')} />
        </ButtonLink>
      }
      videoDescription={t('tipsAndTricks.videoDescription')}
      links={[
        {
          href: 'https://www.youtube.com/c/Marketing360',
          text: t('tipsAndTricks.youTube')
        },
        {
          href: 'https://blog.marketing360.com/',
          text: t('tipsAndTricks.blog')
        },
        {
          href: 'https://updates.marketing360.com/',
          text: t('tipsAndTricks.productUpdates')
        }
      ]}
    />
  );
};

export default DashboardOverviewCard;
