import { apiEamLogin } from '@api/external-auth-management';
import { ads, social } from '@data/productsData';
import { cdnUrl } from '../global';

const { key: socialProductKey } = social;
const { key: adsProductKey } = ads;

export const EAM_PLATFORM_MAPPER = {
  googleAnalytics: {
    label: 'link-account.platforms.google-analytics', // Text to render on front-end
    logo: `${cdnUrl}/external-platforms/logo-google-analytics.svg`,
    platform: 'google', // "platform" param to be passed to the api.
    app: 'analytics' // "app" param to be passed to the api.
  },
  googleAds: {
    label: 'link-account.platforms.google-ads',
    logo: `${cdnUrl}/external-platforms/logo-google-ads.svg`,
    platform: 'google',
    app: 'ads'
  },
  googleSearchConsole: {
    label: 'link-account.platforms.google-sc',
    logo: `${cdnUrl}/external-platforms/logo-google-search-console.svg`,
    platform: 'google',
    app: 'gsc'
  },
  googleMerchantCenter: {
    label: 'link-account.platforms.google-mc',
    logo: `${cdnUrl}/external-platforms/logo-google-merchant-center.svg`,
    platform: 'google',
    app: 'gmc'
  },
  facebookAds: {
    label: 'link-account.platforms.fb-ads',
    logo: `${cdnUrl}/external-platforms/logo-facebook.svg`,
    platform: 'facebook',
    app: 'ads'
  },
  bingAds: {
    label: 'link-account.platforms.bing-ads',
    logo: `${cdnUrl}/external-platforms/logo-bing-ads.svg`,
    platform: 'bing',
    app: 'ads'
  }
  // LinkedIn Currently Not Available
  // linkedinAds: {
  //   label: 'link-account.platforms.linkedin-ads',
  //   logo: `${cdnUrl}/external-platforms/logo-linkedin.svg`,
  //   platform: 'linkedin',
  //   app: 'ads'
  // }
};

// This will be used for the new setup wizard as long well
// as the social settings page.
export const EAM_SOCIAL_PRODUCTS = [
  {
    title: 'facebook',
    text: 'settings.platforms.facebook.description',
    platform: 'facebook',
    publisherID: 2,
    app: socialProductKey
  },
  {
    title: 'instagram',
    text: 'settings.platforms.instagram.description',
    platform: 'instagram',
    app: socialProductKey
  },
  {
    title: 'googleMyBusiness',
    text: 'settings.platforms.googleMyBusiness.description',
    platform: 'google',
    publisherID: 1,
    app: socialProductKey
  },
  {
    title: 'linkedIn',
    text: 'settings.platforms.linkedIn.description',
    platform: 'linkedin',
    app: socialProductKey
  }
];

export const EAM_CONTENT_PRODUCTS = [
  {
    title: 'googleAnalytics',
    text: 'settings.platforms.googleAnalytics.description',
    platform: 'google',
    platformUnderscore: 'google_analytics_settings',
    app: 'analytics'
  },
  {
    title: 'googleSearchConsole',
    text: 'settings.platforms.googleSearchConsole.description',
    platform: 'google',
    platformUnderscore: 'google_search_console_settings',
    app: 'gsc'
  }
];

export const EAM_ADS_PRODUCTS = [
  {
    title: 'googleAds',
    text: 'settings.platforms.googleAds.description',
    platform: 'googleads',
    parentPlatform: 'google',
    platformUnderscore: 'google_ads_account_settings',
    app: adsProductKey
  },
  {
    title: 'googleMerchant',
    text: 'settings.platforms.googleMerchant.description',
    platform: 'googlemerchantcenter',
    platformUnderscore: 'google_merchant_center_settings',
    parentPlatform: 'google',
    app: 'gmc'
  },
  {
    title: 'facebook',
    text: 'settings.platforms.facebook.description',
    platform: 'facebookads',
    platformUnderscore: 'facebook_ads_account_settings',
    parentPlatform: 'facebook',
    app: adsProductKey
  },
  {
    title: 'bingAds',
    text: 'settings.platforms.microsoftAds.description',
    platform: 'bingads',
    platformUnderscore: 'bing_ads_account_settings',
    parentPlatform: 'bing',
    app: adsProductKey
  }
];

// Add this to be reused by Ads and Social setup and settings.
export const connectEamLogin = async ({ payload, platform }) => {
  const { data } = await apiEamLogin({
    isV2: true,
    platform,
    method: 'GET',
    payload
  });
  return data.response.url;
};
