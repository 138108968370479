import { Modal } from 'antd';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const { confirm } = Modal;

const ModalWrapper = ({
  title,
  width,
  afterClose,
  renderOpener,
  renderComponent,
  closable,
  keyboard,
  maskClosable,
  destroyOnClose,
  defaultOpen,
  promptOnClose,
  centered,
  className,
  onCloseQuery
}) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(defaultOpen);
  /**
   * Using reloadOnClose for instances where we want to conditionally query when the modal is closed/canceled.
   * I.e. when an import partially completes and then errors,  some data may have gone through
   * and should be reflected on the page after closing the modal.
   * Different from afterClose which runs every time the modal closes.
   */
  const [reloadOnClose, setReloadOnClose] = useState(false);

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = options => {
    if (options?.reloadOnClose) setReloadOnClose(true);
    /**
     * If promptOnClose is true, then we'll need to pass an object to the handleCloseModal() that fires after a form submit.
     * We need to pass in handleCloseModal({forceClose: true}) so that it won't prompt after a successful form submit.
     */
    if (promptOnClose && !options.forceClose) {
      confirm({
        title: t('messages.confirm.close'),
        content: t('messages.confirm.lost-changes'),
        okText: t('actions.close'),
        okType: 'danger',
        cancelText: t('actions.cancel'),
        onOk: () => setModalVisible(false),
        onCancel: () => false
      });
    } else {
      setModalVisible(false);
    }
  };

  const handleAfterClose = () => {
    if (afterClose) return afterClose();
    if (reloadOnClose && onCloseQuery) {
      setReloadOnClose(false);
      onCloseQuery();
    }
  };

  return (
    <>
      {renderOpener({
        handleOpenModal
      })}
      <Modal
        className={className}
        title={title}
        style={!centered && { top: 20 }}
        centered={centered}
        open={modalVisible}
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
        destroyOnClose={destroyOnClose}
        footer={null}
        width={width}
        afterClose={handleAfterClose}
        closable={closable}
        keyboard={keyboard}
        maskClosable={maskClosable}
      >
        {renderComponent({
          handleCloseModal,
          setReloadOnClose
        })}
      </Modal>
    </>
  );
};

ModalWrapper.defaultProps = {
  width: 800,
  closable: true,
  maskClosable: true,
  keyboard: true,
  destroyOnClose: true,
  defaultOpen: false,
  centered: false
};

ModalWrapper.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
  renderOpener: PropTypes.func.isRequired,
  renderComponent: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  afterClose: PropTypes.func,
  centered: PropTypes.bool,
  className: PropTypes.string,
  closable: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  destroyOnClose: PropTypes.bool,
  keyboard: PropTypes.bool,
  maskClosable: PropTypes.bool,
  promptOnClose: PropTypes.bool,
  onCloseQuery: PropTypes.func
};

export default React.memo(ModalWrapper);
