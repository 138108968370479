/* eslint-disable @typescript-eslint/ban-ts-comment */
import ActivityStream from '@components/activityStream/ActivityStream';
import DashboardContactCard from '@components/dashboard/DashboardContactCard';
import TaskManager from '@components/taskManager/TaskManager';
import DashboardHeader from '@components/dashboard/DashboardHeader';
import DashboardHelpCard from '@components/dashboard/DashboardHelpCard';
import DashboardOverviewCard from '@components/dashboard/DashboardOverviewCard';
import DashboardProductsCard from '@components/dashboard/DashboardProductsCard';
import DashboardSubscriptions from '@components/dashboard/DashboardSubscriptions';
import DashboardUpdates from '@components/dashboard/DashboardUpdates';
import EmailVerificationAlert from '@components/EmailVerificationAlert';
import BillingAccountContextProvider from '@context/BillingAccountContextProvider';
import { GlobalStateContext } from '@context/GlobalContextProvider';
import useTaskManager from '@hooks/dashboard/useTaskManager';
import { Box, Container, Grid, Heading } from '@madwire/m360ui';
import { useContext } from 'react';
import DashboardBackground from './DashboardBackground';

/*
 *
 * Component
 *
 */
const DashboardPage = () => {
  const { user, account } = useContext(GlobalStateContext);
  const { showTaskManager, showOverview } = useTaskManager();

  return (
    <BillingAccountContextProvider>
      <DashboardBackground />
      {/* @ts-ignore */}
      <Box
        mx={{
          overflow: 'hidden',
          backgroundColor: 'transparent',
          position: 'relative',
          marginTop: '-275px'
        }}
      >
        <Container>
          <EmailVerificationAlert />
          <DashboardHeader user={user} />
          {showTaskManager && <TaskManager />}
          <Grid gap={3} columns={[1, null, null, '2fr 1fr']}>
            {/* @ts-ignore */}
            <Box>
              {!showTaskManager && <DashboardUpdates />}
              <DashboardProductsCard />
              <DashboardSubscriptions />
            </Box>
            {/* @ts-ignore */}
            <Box>
              <ActivityStream />
            </Box>
          </Grid>
          <Heading type="h5">Resources</Heading>
          <Grid gap={3} columns={[1, null, 2, showOverview ? 3 : 2]}>
            {showOverview && <DashboardOverviewCard />}
            <DashboardHelpCard supportPin={account?.supportPin} />
            <DashboardContactCard />
          </Grid>
        </Container>
      </Box>
    </BillingAccountContextProvider>
  );
};

export default DashboardPage;
