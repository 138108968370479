/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Link, Text } from '@madwire/m360ui';
import { GlobalStateContext } from '@context/GlobalContextProvider';
import { UPGRADE_BILLING_PHONE_URI } from '@utils/settings/profile/billing/upgrade';
import { truncateText } from '@utils/mxStyles';
import { cdnUrl, findEmployee } from '@utils/global';
import { Trans, useTranslation } from 'next-i18next';
import { FC, useContext } from 'react';
import { MxType } from '@typedefs/General';
import HelpItem from '../HelpItem';

interface Props {
  mx?: MxType;
}

interface Employee {
  email: string;
  phone: string | null;
  name: string;
  photo: string;
}

const MCCard: FC<Props> = ({ mx }): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const { account } = useContext(GlobalStateContext);
  const employee = findEmployee(account?.assignedEmployees) as Employee;
  const phoneNumber = employee?.phone || UPGRADE_BILLING_PHONE_URI.slice(4);

  return employee ? (
    <HelpItem
      imgSrc={
        employee?.photo ? employee.photo : `${cdnUrl}/shared-illustrations/icon-SupportRep.svg`
      }
      title={employee?.name}
      description={
        <>
          {/* @ts-ignore */}
          <Text mx={{ mb: 0 }}>
            {/* @ts-ignore */}
            <Link
              href={`mailto:${employee?.email}`}
              mx={{ maxWidth: '100%', display: 'block', ...truncateText }}
            >
              {employee.email}
            </Link>
          </Text>
          {/* @ts-ignore */}
          <Text>
            {/* @ts-ignore */}
            <Link href={`tel:${phoneNumber}`}>{phoneNumber}</Link>
          </Text>
        </>
      }
      mx={mx}
    />
  ) : (
    <HelpItem
      imgSrc={`${cdnUrl}/shared-illustrations/icon-SupportRep.svg`}
      title={t('taskManager.helpItems.contact.title')}
      description={
        <Trans
          t={t}
          i18nKey="taskManager.helpItems.contact.description"
          values={{ tel: phoneNumber }}
          components={{
            bold: (
              <>
                {/* @ts-ignore */}
                <Link href={`tel:${phoneNumber}`} />
              </>
            )
          }}
        />
      }
      mx={mx}
    />
  );
};

export default MCCard;
