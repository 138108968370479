import { GlobalStateContext } from '@context/GlobalContextProvider';
import useFetchSWR from '@hooks/useFetchSWR';
import { useCallback, useContext } from 'react';
import { apiAccounts } from '@api/core';
import { AccountStatusEnum } from '@typedefs/AccountStatus';
import { handleErrorMessage, isCobrandedAgency } from '@utils/global';
import { Task } from '@typedefs/TaskManager';
import { useToast } from '@madwire/m360ui';
import { ErrorMessage } from '@madwire/m360-navigation-bar';

export type CustomizableTaskManagers = 'onboarding';
interface TaskManagerSettingApiResponse {
  data: {
    response: {
      show: string;
      managers: Record<CustomizableTaskManagers, Task[]> | undefined;
    };
  };
}

const useTaskManager = () => {
  const { toast } = useToast();
  const { account } = useContext(GlobalStateContext);
  const { agencyInfo, statusId } = account;
  const isCobranded = isCobrandedAgency(agencyInfo);
  const showOverview = isCobranded ? agencyInfo.showOverview : true;
  const isTrial = statusId === AccountStatusEnum.Trial;

  const {
    data,
    isLoading: isLoadingTaskManagerStatus,
    mutate
  } = useFetchSWR(
    `v2/accounts/${account.accountNumber}/settings/taskManager`,
    async url => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response: TaskManagerSettingApiResponse = await apiAccounts({
        url,
        method: 'GET'
      });
      return response.data.response;
    },
    { shouldRetryOnError: false }
  );
  const managers = data?.managers;

  const hideTaskManager = useCallback(async () => {
    const payload = {
      show: 'hideTaskManager',
      managers
    };
    try {
      mutate(payload, false);
      await apiAccounts({
        url: `v2/accounts/${account.accountNumber}/settings/taskManager`,
        method: 'put',
        payload
      });
    } catch (e) {
      toast({ message: handleErrorMessage(e as ErrorMessage), type: 'error' });
      mutate();
    }
  }, [account.accountNumber, managers, mutate, toast]);

  const view = data?.show || 'hideTaskManager';
  const showTaskManager = view !== 'hideTaskManager';

  return {
    view,
    onboardingTasks: managers?.onboarding || undefined,
    isTrial,
    showOverview,
    showTaskManager,
    hideTaskManager,
    isLoadingTaskManagerStatus
  };
};

export default useTaskManager;
