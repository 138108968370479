import { GlobalStateContext } from '@context/GlobalContextProvider';
import useCanViewProduct from '@hooks/useCanViewProduct';
import { Box, Card, Image } from '@madwire/m360ui';
import { cdnUrl, isCobrandedAgency } from '@utils/global';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import DashboardProductItem from './LegacyDashboardProductItem';

const DashboardProductsCard = ({ t }) => {
  const { account } = useContext(GlobalStateContext);
  const { agencyInfo } = account;
  const isCobranded = isCobrandedAgency(agencyInfo);
  const logoSrc = isCobranded ? agencyInfo.logo : `${cdnUrl}/logo-marketing360.svg`;
  const logoAlt = isCobranded ? agencyInfo.displayName : t('common.products.marketing.title');
  const logoMx = isCobranded ? { width: 'full', maxWidth: 150, maxHeight: 150 } : null;
  const { visibleProducts } = useCanViewProduct();

  // Separate out 'active' products from 'inactive' products.
  // Active products should be grouped at the top and inactive products at the bottom.
  const { activeProducts, inactiveProducts } = visibleProducts.reduce(
    (acc, product) => {
      // Technically provisioning products are active
      if (product.status === 'active' || product.status === 'provisioning') {
        acc.activeProducts.push(product);
      } else {
        acc.inactiveProducts.push(product);
      }
      return acc;
    },
    {
      activeProducts: [],
      inactiveProducts: []
    }
  );

  return (
    <Card mx={{ px: 0 }}>
      <Box mx={{ mb: 9, px: [6, 9] }}>
        <Image src={logoSrc} alt={logoAlt} mx={logoMx} />
      </Box>
      {activeProducts.map((product, index) => (
        <DashboardProductItem key={product.id} t={t} product={product} noBorder={index === 0} />
      ))}
      {inactiveProducts.map(product => (
        <DashboardProductItem key={product.id} t={t} product={product} />
      ))}
    </Card>
  );
};

DashboardProductsCard.propTypes = {
  t: PropTypes.func.isRequired
};

export default DashboardProductsCard;
