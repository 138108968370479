import { apiReputation } from '@api/reputation';
import { GlobalDispatchContext, GlobalStateContext } from '@context/GlobalContextProvider';
import useFetchSWR from '@hooks/useFetchSWR';
import { getAccountNumber } from '@utils/auth';
import { simplifyBusiness } from '@utils/businessInformation/simplifyBusiness';
import { getBusiness } from '@utils/global';
import { useContext } from 'react';

const useFetchBusinesses = (swrOptions = {}, showClosed = true, pageSize = 500) => {
  const { businesses, selectedBusiness } = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const { endpoint, makeRequest } = apiReputation({
    url: `business`,
    method: 'get',
    payload: {
      account_id: getAccountNumber(),
      page_size: pageSize,
      ...(showClosed && { show_closed: 1 })
    },
    showEndpoint: true
  });

  const { isLoading, error, mutate, isValidating, data } = useFetchSWR(
    endpoint,
    async () => {
      const response = await makeRequest();

      const simplifiedBusinesses = simplifyBusiness(response.data.results);

      const savedBusiness = getBusiness();
      dispatch({
        type: 'SET_GLOBAL_BUSINESS',
        businesses: simplifiedBusinesses,
        savedBusiness
      });

      return response.data;
    },
    swrOptions
  );

  return {
    businesses,
    businessesFull: data?.results,
    businessesError: error,
    businessesLoading: isLoading,
    businessesValidating: isValidating,
    dispatch,
    mutate,
    selectedBusiness
  };
};

export default useFetchBusinesses;
