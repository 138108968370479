import productsData from '@data/productsData';
import useProductSetup from '@hooks/useProductSetup';
import { Flex, Image, Link, Text } from '@madwire/m360ui';
import { cdnUrl } from '@utils/global';
import { useTranslation } from 'next-i18next';
import NextLink from 'next/link';
import PropTypes from 'prop-types';

const DashboardProductItem = ({ product }) => {
  const { t } = useTranslation('dashboard');
  const productDetails = productsData[product.name];
  const { isProductSetupComplete } = useProductSetup();

  // Only show products that we have the hard-coded details for.
  if (!productDetails) {
    return null;
  }

  const isInactive =
    product.status !== 'active' &&
    product.status !== 'provisioning' &&
    product.phase !== 'maintenance';

  // If a product is not set up, we display different
  // text copy and the color should be 'cta.link'.
  const productSetupComplete = isProductSetupComplete(product.name);

  const productContent = (
    <Flex
      mx={{
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: isInactive ? 0.5 : 1,
        color: 'text.body',
        '&:hover, &:focus, &:hover p, &:focus p, &:hover svg, &:focus svg': {
          color: 'blue.900'
        }
      }}
    >
      <Image width="30" src={`${cdnUrl}/product-${product.name}.svg`} alt="" />

      <Text
        as="span"
        mx={{
          mt: 2,
          textAlign: 'center',
          color: 'inherit',
          // Design review concluded that 13px is the value they want here even though that value is not in the theme
          fontSize: 13
        }}
      >
        {t(`common:products.${product.name}.title`)}
      </Text>
    </Flex>
  );

  // Some products need to link externally
  if (
    productDetails.shouldRouteExternally === true ||
    (typeof productDetails.shouldRouteExternally === 'function' &&
      productDetails.shouldRouteExternally(product.status) === true)
  ) {
    const anchorProps = productDetails.routeExternallyInNewTab
      ? {
          target: '_blank',
          rel: 'noopener noreferrer'
        }
      : {};
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Link href={productDetails.link} mx={{ textAlign: 'center' }} {...anchorProps}>
        {productContent}
      </Link>
    );
  }

  let productLink = productDetails.setupLink;

  if (isInactive) {
    productLink = productDetails.inactiveLink;
  } else if (product.status === 'provisioning') {
    productLink = `/start/preparing/${product.name}`;
  } else if (product.phase === 'maintenance') {
    productLink = `/start/maintenance/${product.name}`;
  } else if (productSetupComplete) {
    productLink = productDetails.link;
  }

  // For all other products, we use 'next/link'
  return (
    <NextLink href={productLink} passHref>
      <Link mx={{ textAlign: 'center' }}>{productContent}</Link>
    </NextLink>
  );
};

DashboardProductItem.propTypes = {
  product: PropTypes.object.isRequired
};

export default DashboardProductItem;
