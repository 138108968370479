import useCanViewProduct from '@hooks/useCanViewProduct';
import { Card, Flex, Grid, Heading, Link, useResponsiveValue } from '@madwire/m360ui';
import { useTranslation } from 'next-i18next';
import NextLink from 'next/link';
import DashboardProductItem from './DashboardProductItem';

const DashboardProductsCard = () => {
  const { visibleProducts } = useCanViewProduct();
  const isMobile = useResponsiveValue([true, true, false, false]);
  const { t } = useTranslation('dashboard');

  // Separate out 'active' products from 'inactive' products.
  // Active products should be grouped at the top and inactive products at the bottom.
  const { activeProducts, inactiveProducts } = visibleProducts.reduce(
    (acc, product) => {
      // Technically provisioning products are active
      if (product.status === 'active' || product.status === 'provisioning') {
        acc.activeProducts.push(product);
      } else {
        acc.inactiveProducts.push(product);
      }
      return acc;
    },
    {
      activeProducts: [],
      inactiveProducts: []
    }
  );

  return (
    <Card mx={{ mb: 3 }}>
      <Flex mx={{ justifyContent: 'space-between', alignItems: 'center', mb: 9 }}>
        <Heading as="h2" type="h6" mx={{ margin: 0 }}>
          {t('dashboard.yourApps')}
        </Heading>
        <NextLink href="/settings/app-visibility" passHref>
          <Link mx={{ textAlign: 'center', paddingX: 0 }}>{t('dashboard.customize')}</Link>
        </NextLink>
      </Flex>
      <Grid columns={[3, 5, 6, 8]} gap={[4, 5]}>
        {activeProducts.map(product => (
          <DashboardProductItem key={product.id} product={product} />
        ))}
        {!isMobile &&
          inactiveProducts.map(product => (
            <DashboardProductItem key={product.id} product={product} />
          ))}
      </Grid>
    </Card>
  );
};

DashboardProductsCard.propTypes = {};

export default DashboardProductsCard;
