import { apiBrandProfile } from '@api/brandProfile';
import useFetchSWR from '@hooks/useFetchSWR';
import { useToast } from '@madwire/m360ui';
import { useTranslation } from 'next-i18next';

const useBrandAssets = () => {
  const { toast } = useToast();
  const { t } = useTranslation();

  const { data, ...rest } = useFetchSWR('assets', async url => {
    try {
      const { data: { response } = {} } = await apiBrandProfile({
        url,
        method: 'get'
      });
      return response;
    } catch (error) {
      toast({ message: t('common:messages.error.plain'), type: 'error' });
    }
  });

  return { ...data, ...rest };
};

export default useBrandAssets;
