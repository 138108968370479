// import DashboardContentDefault from '@components/dashboard/DashboardContentDefault';
import DashboardPage from '@components/dashboard/DashboardPage';
import LegacyDashboardPage from '@components/dashboard/legacy/LegacyDashboardPage';
import { GlobalStateContext } from '@context/GlobalContextProvider';
import defaultPage from '@hocs/defaultPage';
import getTranslationProps from '@utils/getTranslationProps';
import { useContext } from 'react';

const DashboardIndex = () => {
  const {
    account: { billingType }
  } = useContext(GlobalStateContext);
  return billingType === 'stripe' ? <DashboardPage /> : <LegacyDashboardPage />;
};

export const getStaticProps = getTranslationProps(['dashboard']);

export default defaultPage(DashboardIndex, {
  metaTitle: 'Dashboard'
});
