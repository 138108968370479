import { apiDirectConnect } from '@api/direct-connect';
import { getAccountNumber } from '@utils/auth';
import useFetchSWR from '../useFetchSWR';

const useDirectLocationRequest = () => {
  // Gets list of already connected locations
  const { endpoint: locationEndpoint, makeRequest: locationRequest } = apiDirectConnect({
    url: 'direct-connect/location',
    method: 'GET',
    payload: { accountID: getAccountNumber() },
    showEndpoint: true
  });

  const {
    data: locationData,
    error,
    loading,
    mutate
  } = useFetchSWR(
    locationEndpoint,
    async () => {
      const { data: responseData } = await locationRequest();
      // endpoint returns 204 when no data so responseData is "".
      return responseData || [];
    },
    { shouldRetryOnError: false }
  );

  return {
    locationData,
    error,
    loading,
    mutate
  };
};

export default useDirectLocationRequest;
