/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CardDivider, Collapse, Flex } from '@madwire/m360ui';
import { helpItemBaseHeight } from '@utils/dashboard/dashboardConsts';
import TaskProgress from './TaskProgress';
import Tasks from './Tasks';
import Title from './Title';
import MCCard from './HelpItems/MCCard';
import GetDemo from './HelpItems/GetDemo';
import IntroVideo from './HelpItems/IntroVideo';

/*
 * Types
 */
type MobileLayoutProps = {
  isOpen: boolean;
};

/*
 *
 * Component
 *
 */
const MobileLayout = ({ isOpen }: MobileLayoutProps) => (
  <>
    <Title />
    <TaskProgress />
    {/* collapse #1, for tasks list */}
    <Collapse isOpen={isOpen}>
      <CardDivider mx={{ my: 7 }} />
      {/* @ts-ignore */}
      <Flex mx={{ gap: 5 }} flexDirection="column">
        <Tasks />
      </Flex>
    </Collapse>
    <CardDivider mx={{ my: 7 }} />
    {/* collapse #2, for demo/video/contact content */}
    <Collapse isOpen={isOpen} startingHeight={helpItemBaseHeight}>
      {/* demo/video/contact (DashboardGettingStartedHelpItems) container */}
      {/* @ts-ignore */}
      <Flex mx={{ gap: 7 }} flexDirection="column">
        <MCCard />
        <GetDemo />
        <IntroVideo />
      </Flex>
    </Collapse>
  </>
);

export default MobileLayout;
