import { Link } from '@madwire/m360ui';
import NextLink from 'next/link';
import { FC, ReactNode } from 'react';
import { GenericObject, MxType } from '../typedefs/General';

interface WrappedLinkProps extends GenericObject {
  href: string;
  children?: ReactNode;
  mx?: MxType;
  onClick?: () => void;
}

const WrappedLink: FC<WrappedLinkProps> = ({
  href,
  children = null,
  mx = {},
  onClick = null,
  ...restLinkProps
}) => (
  <NextLink href={href} passHref>
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore */}
    <Link
      mx={{ ...mx }}
      onClick={onClick}
      {...restLinkProps} // eslint-disable-line react/jsx-props-no-spreading
    >
      {children}
    </Link>
  </NextLink>
);

export default WrappedLink;
