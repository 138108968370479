import ProductTitle from '@components/dashboard/legacy/LegacyProductTitle';
import productsData from '@data/productsData';
import useProductSetup from '@hooks/useProductSetup';
import { Box, Flex, Grid, Image, Link, Text } from '@madwire/m360ui';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { cdnUrl } from '@utils/global';
import NextLink from 'next/link';
import PropTypes from 'prop-types';

const DashboardProductItem = ({ t, product, noBorder }) => {
  const productDetails = productsData[product.name];
  const { isProductSetupComplete } = useProductSetup();

  // Only show products that we have the hard-coded details for.
  if (!productDetails) {
    return null;
  }

  const isInactive =
    product.status !== 'active' &&
    product.status !== 'provisioning' &&
    product.phase !== 'maintenance';

  // If a product is not set up, we display different
  // text copy and the color should be 'cta.link'.
  const productSetupComplete = isProductSetupComplete(product.name);

  let productDescription = t(`common:products.${product.name}.short_description`);
  if (!productSetupComplete) {
    productDescription = t(`common:products.${product.name}.short_description_preview`);
  }

  const productContent = (
    <Box
      mx={{
        py: 4,
        px: [6, 9],
        borderTop: noBorder ? null : '1px solid',
        borderColor: 'component.border'
      }}
    >
      <Grid
        gap={4}
        columns="30px auto 24px"
        mx={{
          alignItems: 'center',
          opacity: isInactive ? 0.5 : 1,
          color: 'text.body',
          '&:hover, &:focus, &:hover p, &:focus p, &:hover svg, &:focus svg': {
            color: 'blue.900'
          }
        }}
      >
        <Image width="30" src={`${cdnUrl}/product-${product.name}.svg`} alt={product.name} />
        <Flex
          mx={{
            alignItems: [null, null, 'center'],
            justifyContent: [null, null, 'space-between'],
            flexDirection: ['column', null, 'row']
          }}
        >
          <ProductTitle productKey={product.name} />
          <Text
            mx={{ mb: 0, color: productSetupComplete || isInactive ? 'text.body' : 'cta.link' }}
          >
            {productDescription}
          </Text>
        </Flex>
        <Flex
          mx={{
            alignItems: 'center'
          }}
        >
          <ChevronRight />
        </Flex>
      </Grid>
    </Box>
  );

  // Some products need to link externally
  if (
    productDetails.shouldRouteExternally === true ||
    (typeof productDetails.shouldRouteExternally === 'function' &&
      productDetails.shouldRouteExternally(product.status) === true)
  ) {
    const anchorProps = productDetails.routeExternallyInNewTab
      ? {
          target: '_blank',
          rel: 'noopener noreferrer'
        }
      : {};
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Link href={productDetails.link} {...anchorProps}>
        {productContent}
      </Link>
    );
  }

  let productLink = productDetails.setupLink;

  if (isInactive) {
    productLink = productDetails.inactiveLink;
  } else if (product.status === 'provisioning') {
    productLink = `/start/preparing/${product.name}`;
  } else if (product.phase === 'maintenance') {
    productLink = `/start/maintenance/${product.name}`;
  } else if (productSetupComplete) {
    productLink = productDetails.link;
  }

  // For all other products, we use 'next/link'
  return (
    <NextLink href={productLink} passHref>
      <Link>{productContent}</Link>
    </NextLink>
  );
};

DashboardProductItem.propTypes = {
  t: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  noBorder: PropTypes.bool
};

export default DashboardProductItem;
