import { isProduction, isStage } from '@utils/environment';
import { apiRequest, generateDataOrParams } from './apiConfig';

/**
 * This Service is EXEMPT from the app.marketing360.com/api/{serviceName} convention.
 */

export const baseURL = isProduction
  ? `https://www.topratedlocal.com/api/2.0.0`
  : `https://${isStage ? 'stage' : 'qa'}.topratedlocal.com/api/2.0.0`;

export const TrlUrl = isProduction
  ? `https://www.topratedlocal.com`
  : `https://${isStage ? 'stage' : 'qa'}.topratedlocal.com`;

export const LOGO_BASE_URL = isProduction
  ? `https://d22s7xnafxduco.cloudfront.net/logos/`
  : `https://d22s7xnafxduco.cloudfront.net/logos_dev/`;

// Creating the base request
export const apiReputation = ({ url, method, payload, cache = false, showEndpoint = false }) => {
  const baseRequest = apiRequest(baseURL);
  const dataOrParams = generateDataOrParams(method);
  const config = {
    url,
    method,
    [dataOrParams]: payload,
    cache
  };

  // Tacking these lines onto api helpers will expose the full url that any
  // individual request targets. In turn, it can be used as a stable key in our
  // swr cache that is tightly coupled to the request itself. This covers the
  // account change case any time that the number is part of the URL.
  if (showEndpoint) {
    return {
      endpoint: `${baseURL}/${baseRequest.getUri(config)}`,
      makeRequest: () => baseRequest(config)
    };
  }

  // By default, we'll return the regular old promise so this change can't
  // break anything.
  return baseRequest(config);
};

export const yextUrl = isProduction
  ? 'https://www.topratedlocal.com/v3.0/yext'
  : `https://${isStage ? 'stage' : 'qa'}.topratedlocal.com/v3.0/yext`;

export const apiYext = ({ url, method, payload }) => {
  const listingsRequest = apiRequest(yextUrl);
  const dataOrParams = generateDataOrParams(method);
  return listingsRequest({
    url,
    method,
    [dataOrParams]: payload,
    cache: false
  });
};
