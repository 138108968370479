import { apiRequest, generateDataOrParams, getApiEnvironment } from './apiConfig';

// eslint-disable-next-line no-nested-ternary
export const baseURL = `https://${getApiEnvironment()}.marketing360.com/api/direct-connect`;

// TODO: Use TLS on this API url.
export const apiDirectConnect = ({
  url,
  method,
  payload,
  headers,
  cache = false,
  showEndpoint = false
}) => {
  const baseRequest = apiRequest(baseURL);
  const dataOrParams = generateDataOrParams(method);
  const config = {
    url,
    method,
    headers,
    [dataOrParams]: payload,
    cache
  };
  // Tacking these lines onto api helpers will expose the full url that any
  // individual request targets. In turn, it can be used as a stable key in our
  // swr cache that is tightly coupled to the request itself. This covers the
  // account change case any time that the number is part of the URL.
  if (showEndpoint) {
    return {
      endpoint: `${baseURL}/${baseRequest.getUri(config)}`,
      makeRequest: () => baseRequest(config)
    };
  }

  // By default, we'll return the regular old promise so this change can't
  // break anything.
  return baseRequest(config);
};
