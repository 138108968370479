import { apiDirectConnect } from '@api/direct-connect';
import { getAccountNumber } from '@utils/auth';
import { useMemo } from 'react';
import useFetchSWR from '../useFetchSWR';

const useDirectRequest = () => {
  // Pulls any keys associated with this account from places like google and facebook
  const { endpoint: directEndpoint, makeRequest: directRequest } = apiDirectConnect({
    url: 'direct-connect',
    method: 'GET',
    payload: { accountID: getAccountNumber() },
    showEndpoint: true
  });

  const {
    data: directConnectResponse,
    loading,
    error,
    mutate
  } = useFetchSWR(
    directEndpoint,
    async () => {
      const { data: responseData } = await directRequest();
      return responseData;
    },
    { shouldRetryOnError: false }
  );

  const { googleDirectConnect, facebookDirectConnect } = useMemo(
    () =>
      (directConnectResponse || []).reduce((acc, token) => {
        if (token.publisherID === 1) {
          return {
            ...acc,
            googleDirectConnect: token
          };
        }
        if (token.publisherID === 2) {
          return {
            ...acc,
            facebookDirectConnect: token
          };
        }
        return acc;
      }, {}),
    [directConnectResponse]
  );

  return {
    googleDirectConnect,
    facebookDirectConnect,
    loading,
    error,
    mutate
  };
};

export default useDirectRequest;
