import { withBaseReducer } from '@utils/reducerHelpers';
import { useCallback, useEffect, useReducer } from 'react';

/**
 * This Hook takes a query function which must return a promise. It will handle loading, error, and making the query with useEffect.
 * @param {function} query - Function that returns a promise.
 */

const initialState = {
  loading: true,
  error: false,
  response: undefined
};

const reducer = withBaseReducer(function (state, action) {
  switch (action.type) {
    case 'SET_RESPONSE':
      return {
        ...state,
        loading: false,
        error: false,
        response: action.response
      };
    default:
      return state;
  }
});

// Allow overriding data key in order to make it possible for it to be
// interchangeable with the swr hook (which provides "data").
const useFetchData = ({ query, dataKey = 'response' }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const getInitialData = useCallback(
    async canceled => {
      if (canceled === true) return;
      dispatch({ type: 'LOADING', loading: true });
      try {
        const response = await query();
        dispatch({ type: 'SET_RESPONSE', response });
      } catch (error) {
        dispatch({ type: 'ERROR', error });
      }
    },
    [query]
  );
  useEffect(() => {
    // The "canceled" should prevent the dispatch on unmounted components.
    // https://github.com/jlengstorf/jamstack-intro/blob/final/sections/todos/src/pages/index.js
    let canceled = false;
    getInitialData(canceled);
    return () => {
      // if the component unmounts, we want to cancel any active loads
      canceled = true;
    };
  }, [getInitialData]);
  return {
    loading: state.loading,
    error: state.error,
    [dataKey]: state.response,
    getInitialData,
    state,
    dispatch
  };
};

export default useFetchData;
