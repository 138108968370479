import useBrandAssets from '@hooks/brandProfile/useBrandAssets';
import useCrmContactCount from '@hooks/crm/useCrmContactCount';
import useFetchBusinesses from '@hooks/useFetchBusinesses';
import usePayments from '@hooks/usePayments';
import { Task } from '@typedefs/TaskManager';
import {
  calculateEstimatedMinutes,
  calculateTasksRemaining,
  calculatePercentCompleted
} from '@utils/dashboard/taskManagerCalculations';
import { CONFIG } from '@utils/environment';
import { useTranslation } from 'react-i18next';

const isLoadingData = (
  isLoadingLocations: boolean,
  isLoadingBrandProfile: boolean,
  isLoadingContacts: boolean,
  isLoadingPayments: boolean
) => isLoadingLocations || isLoadingBrandProfile || isLoadingContacts || isLoadingPayments;

const useGettingStartedTaskManager = () => {
  const { t } = useTranslation('dashboard');
  const {
    businesses: locations,
    businessesLoading: isLoadingLocations,
    businessesError
  } = useFetchBusinesses();
  const { logos, loading: isLoadingBrandProfile, error: brandAssetsError } = useBrandAssets();
  const { loading: isLoadingPayments, onboardingStatus, error: paymentsError } = usePayments();
  const {
    contactsCount,
    isLoading: isLoadingContacts,
    error: crmContactError
  } = useCrmContactCount();

  const isLoading = isLoadingData(
    isLoadingLocations,
    isLoadingBrandProfile,
    isLoadingContacts,
    isLoadingPayments
  );

  const tasks: Task[] = [
    {
      id: 0,
      title: t('taskManager.tasks.addLocations.title'),
      description: t('taskManager.tasks.addLocations.description'),
      minutesRequired: 3,
      isComplete: Boolean(locations?.length),
      link: '/reputation/setup',
      loadingError: businessesError
    },
    {
      id: 1,
      title: t('taskManager.tasks.setUpBrandProfile.title'),
      description: t('taskManager.tasks.setUpBrandProfile.description'),
      minutesRequired: 10,
      isComplete: Boolean(logos?.find((logo: { type: string }) => logo.type === 'primary')),
      link: '/brand-profile/assets',
      loadingError: brandAssetsError
    },
    {
      id: 2,
      title: t('taskManager.tasks.addContacts.title'),
      description: t('taskManager.tasks.addContacts.description'),
      minutesRequired: 15,
      isComplete: Boolean(contactsCount > 1),
      link: '/crm/contacts',
      loadingError: crmContactError
    },
    {
      id: 3,
      title: t('taskManager.tasks.setUpPayments.title'),
      description: t('taskManager.tasks.setUpPayments.description'),
      minutesRequired: 15,
      isComplete: Boolean(onboardingStatus === 3 || onboardingStatus === 4),
      isInProgress: Boolean(onboardingStatus === 2),
      link: CONFIG.paymentsUrl,
      loadingError: paymentsError
    }
  ];

  const tasksCount = tasks.length;
  const estimatedMinutes = calculateEstimatedMinutes(tasks);
  const tasksRemaining = calculateTasksRemaining(tasks);
  const percentCompleted = calculatePercentCompleted(tasksCount, tasksRemaining);

  return {
    tasks,
    isLoading,
    tasksCount,
    estimatedMinutes,
    tasksRemaining,
    percentCompleted
  };
};

export default useGettingStartedTaskManager;
