/* eslint-disable @typescript-eslint/ban-ts-comment */
import WrappedLink from '@components/WrappedLink';
import { useTaskManagerContextValue } from '@context/TaskManagerContextProvider';
import {
  Box,
  ButtonPrimary,
  Image,
  Flex,
  Tag,
  Text,
  AlertError,
  useResponsiveValue
} from '@madwire/m360ui';
import { Check } from '@mui/icons-material';
import { useTranslation } from 'next-i18next';
import { cdnUrl } from '@utils/global';
import useTaskManager from '@hooks/dashboard/useTaskManager';
import { Task } from '@typedefs/TaskManager';

/*
 *
 * Component
 *
 */
const Tasks = () => {
  const { tasks, taskManagerName, tasksRemaining } = useTaskManagerContextValue();
  const { t } = useTranslation('dashboard');
  const { hideTaskManager } = useTaskManager();
  const isMobile = useResponsiveValue([true, true, false]);

  const getTaskBackground = (task: Task) => {
    if (task.isComplete) {
      return '#F3F9F2';
    }
    // deliberate non-theme values for these bg colors,
    // approved/requested by product/design as one-off exceptions:
    // they're based on opacity reductions to green.100 & blue.100,
    // to allow green/blue tags to appear on them when necessary
    if (taskManagerName === 'onboarding' && !task.isDisabled && !task.isInProgress) {
      return '#FFFAF4';
    }
    return '#F1F9FF';
  };

  return tasksRemaining <= 0 ? (
    // @ts-ignore
    <Flex mx={{ gap: 10, alignItems: 'center' }}>
      <Image src={`${cdnUrl}/shared-illustrations/completed-tasklist.svg`} alt="" />
      {/* @ts-ignore */}
      <Box>
        {/* @ts-ignore */}
        <Text mx={{ mb: 2, fontSize: 'h6', fontWeight: 'semibold' }}>
          {t('taskManager.completedTasks.congratulations')}
        </Text>
        {/* @ts-ignore */}
        <Text mx={{ fontSize: 'h6', fontWeight: 'normal' }}>
          {t('taskManager.completedTasks.description')}
        </Text>
        {/* @ts-ignore */}
        <ButtonPrimary size="sm" onClick={() => hideTaskManager()}>
          {t('taskManager.completedTasks.cta')}
        </ButtonPrimary>
      </Box>
    </Flex>
  ) : (
    // Without the wrapping fragment, DesktopLayout and MobileLayout have a problem with <Tasks /> not returning
    // a valid JSX.Element
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {tasks?.map(
        task =>
          task.isVisible !== false &&
          (task.loadingError ? (
            <AlertError key={task.title} message={task.loadingError} />
          ) : (
            // @ts-ignore
            <Flex
              key={task.title}
              alignItems="center"
              mx={{
                borderRadius: 1,
                backgroundColor: getTaskBackground(task),
                px: 5,
                py: 3,
                opacity: task.isDisabled ? 0.5 : 1
              }}
            >
              {/* @ts-ignore */}
              <Flex
                alignItems="center"
                justifyContent="center"
                mx={{
                  width: 24,
                  height: 24,
                  backgroundColor: task.isComplete ? 'product.creative.400' : 'white',
                  borderStyle: task.isComplete ? 'none' : 'solid',
                  borderWidth: 1,
                  borderColor: 'component.inputBorder',
                  borderRadius: 2,
                  mr: 5
                }}
              >
                {task.isComplete && <Check style={{ color: 'white', width: 18, height: 18 }} />}
              </Flex>
              {/* @ts-ignore */}
              <Flex
                mx={{ flex: 1 }}
                flexDirection={isMobile ? 'column' : 'row'}
                alignItems={isMobile ? 'flex-start' : 'center'}
              >
                {/* @ts-ignore */}
                <Box mx={{ flex: 1, mr: isMobile ? 0 : 5, mb: isMobile ? 1 : 0 }}>
                  <>
                    <WrappedLink
                      href={task.link || ''}
                      mx={{
                        mb: 1,
                        fontSize: 'body',
                        color: 'cta.link',
                        pointerEvents: task.isDisabled && 'none',
                        cursor: task.isDisabled && 'default'
                      }}
                    >
                      {task.title}
                    </WrappedLink>
                    {/* @ts-ignore */}
                    <Text mx={{ mb: 0 }}>{task.description}</Text>
                  </>
                </Box>
                {task.isComplete && (
                  <Tag color="green" mx={{ textTransform: 'lowercase', height: 24 }}>
                    {t('taskManager.completed')}
                  </Tag>
                )}
                {task.isInProgress && (
                  <Tag color="blue" mx={{ textTransform: 'lowercase', height: 24 }}>
                    {t('taskManager.inProgress')}
                  </Tag>
                )}
                {taskManagerName === 'onboarding' ? (
                  // @ts-ignore
                  <Flex flexDirection="column" mx={{ alignItems: 'flex-end', gap: 1 }}>
                    {!task.isComplete && !task.isInProgress && !task.isDisabled && (
                      <Tag color="orange" mx={{ textTransform: 'lowercase', height: 24 }}>
                        {t('taskManager.actionNeeded')}
                      </Tag>
                    )}
                    {!task.isComplete && !task.isInProgress && (
                      // @ts-ignore
                      <Text as="span" mx={{ mb: 0, color: 'text.gray', fontSize: 12 }}>
                        {t('taskManager.minutesEstimate', { count: task.minutesRequired })}
                      </Text>
                    )}
                  </Flex>
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {!task.isComplete && !task.isInProgress && (
                      // @ts-ignore
                      <Text as="span" mx={{ mb: 0, color: 'text.gray', fontSize: 12 }}>
                        {t('taskManager.minutesEstimate', { count: task.minutesRequired })}
                      </Text>
                    )}
                  </>
                )}
              </Flex>
            </Flex>
          ))
      ) || []}
    </>
  );
};

export default Tasks;
