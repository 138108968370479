/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Heading, Text } from '@madwire/m360ui';
import { useTranslation } from 'next-i18next';

const Title = () => {
  const { t } = useTranslation('dashboard');
  return (
    <>
      <Heading type="h5" as="h2" mx={{ mb: 1 }}>
        {t('taskManager.title')}
      </Heading>
      {/* @ts-ignore */}
      <Text mx={{ mb: 2 }}>{t('taskManager.description')}</Text>
    </>
  );
};

export default Title;
