import VideoEmbedModal from '@components/VideoEmbedModal';
import { useTranslation } from 'next-i18next';

const DashboardTipsAndTricksModal = () => {
  const { t } = useTranslation('dashboard');
  return (
    <VideoEmbedModal
      title={t('tipsAndTricks.videoTitle')}
      src="https://player.vimeo.com/video/250147301?autoplay=1"
      embedOptions={{ vimeo: { playerOptions: { autoplay: true } } }}
    />
  );
};

export default DashboardTipsAndTricksModal;
