/* eslint-disable @typescript-eslint/ban-ts-comment */
import WrappedLink from '@components/WrappedLink';
import { Avatar, Box, ButtonLink, Flex, Heading, Link, Text, useM360UI } from '@madwire/m360ui';
import { MxType } from '@typedefs/General';
import { ReactNode } from 'react';

/*
 * Types
 */
type HelpItemProps = {
  title: string;
  description: ReactNode;
  imgSrc: string;
  cta?: string;
  action?: string | (() => void);
  mx?: MxType;
};

/*
 *
 * Component
 *
 */
const HelpItem = ({ title, description, imgSrc, cta, action, mx = {} }: HelpItemProps) => {
  const { theme } = useM360UI();
  const isJsAction = typeof action === 'function';
  const isLinkAction = typeof action === 'string';
  const isInternalLink = isLinkAction && action.startsWith('/');
  const isExternalLink = isLinkAction && !isInternalLink;
  const ctaMx = {
    fontWeight: 'semibold',
    p: 0,
    lineHeight: 1,
    height: 'auto'
  };

  return (
    // @ts-ignore
    <Flex alignItems="center" mx={{ ...mx }}>
      <Avatar
        src={imgSrc}
        mx={{
          '& img': { width: 100, height: 100, fontSize: theme.fontSizes[7] },
          width: 100,
          height: 100,
          mr: 7,
          flexShrink: 0,
          borderRadius: '50%',
          backgroundColor: theme.colors.blue[150]
        }}
        name={title}
      />
      {/* @ts-ignore */}
      <Box mx={{ flex: 1 }}>
        <Heading type="h6" as="h3" mx={{ my: 0 }}>
          {title}
        </Heading>
        {/* @ts-ignore */}
        <Text mx={{ mb: 1 }}>{description}</Text>
        {isInternalLink && (
          <WrappedLink href={action} mx={ctaMx}>
            {cta}
          </WrappedLink>
        )}
        {isExternalLink && (
          // @ts-ignore
          <Link href={action} target="_blank" rel="noopener noreferrer" mx={ctaMx}>
            {cta}
          </Link>
        )}
        {isJsAction && (
          // @ts-ignore
          <ButtonLink onClick={action} mx={ctaMx}>
            {cta}
          </ButtonLink>
        )}
      </Box>
    </Flex>
  );
};

export default HelpItem;
